import axios from 'axios';

const api = axios.create({
  // baseURL: 'http://localhost:8000'
    baseURL: 'https://s3integracionapiservicios.idooprod.com'
});
api.interceptors.request.use(
    config => {
      const token = localStorage.getItem("token");
      if (token !== null && token !== 'null' && token !== 'null-new') {
        // console.log('tenemos el token..!!!', token)
        config.headers.common["Authorization"] = `JWT ${token}`;
      }
      return config;
    },
    error => {
      return Promise.reject(error);
    }
);
export default api;
