import store from "@/store";

export default class Incidencias {
    static async all(axios) {
        const response = await axios?.post(
            "/graphql/",
            `query{
                  incidenciasList{
                    results{
                      fotos{
                        foto
                        id
                      }
                      descripcion
                      id
                      order{
                        asin
                        orderNumber
                      }
                      user{
                        username
                      }
                      fotos{
                        foto
                        id
                      }
                      status
                      date
                    }
                  }
                }`,
            {
                headers: {
                    "Content-Type": "application/graphql",
                },
            }
        );
        var incidenciasList = response.data.data.incidenciasList.results.reverse();

        for(var i = 0; i < incidenciasList.length; i++) {
            incidenciasList[i].fotos = incidenciasList[i].fotos.map(node => node.foto).join(' | ');
        }
        return response.data.data?.incidenciasList.results;
    }

    static async retrieve(axios, id) {
        const response = await axios
            .post(
                "/graphql/",
                `query{
                      incidenciasRetrieve(id:${id}){
                        descripcion
                        id
                        fotos{
                          foto
                          id
                        }
                        status
                        date
                        __typename
                      }
                    }`,
                {
                    headers: {
                        "Content-Type": "application/graphql",
                    },
                }
            )
            .catch(function (error) {
                if (error.response) {
                    console.log("primer strike");
                    return null;
                } else if (error.request) {
                    console.log("segudo strike");

                    return null;
                } else {
                    console.log("3er strike");
                    return null;
                }
            });

        if (response) {
            if (
                response.data.data.incidenciasRetrieve
            ) {
                response.data.data.incidenciasRetrieve.compressed = {
                    fotos: response.data.data.incidenciasRetrieve.fotos.map(node => node.foto).join(' | '),
                };
                response.data.data.incidenciasRetrieve.fotos = response.data.data.incidenciasRetrieve.fotos.map(node => node.foto).join(' | ')
            }
            return response.data.data.incidenciasRetrieve;
        }
    }

    static async edit(
        axios,
        id,
        status,
    ) {
        // let idEmpoyment = Utils.generateString()
        // idEmpoyment: "${idEmpoyment}",

        const response = await axios?.post(
            "/graphql/",
            `mutation{
                  updateIncidencias(newIncidencias:{
                    id:${id}
                    status:${status}
                  }){
                    incidencias{
                      status
                    }
                    ok
                    errors{
                      field
                      messages
                    }
                  }
                }`,
            {
                headers: {
                    "Content-Type": "application/graphql",
                },
            }
        ).catch(function (error) {
            if (error.response) {
                store.dispatch('actionsSetErrorMessage', error.response);
                console.log("primer strike");
                return null;
            } else if (error.request) {
                console.log("segudo strike");
                store.dispatch('actionsSetErrorMessage', error.request);

                return null;
            } else {
                console.log("3er strike");
                store.dispatch('actionsSetErrorMessage', '3er strike');

                return null;
            }
        });
        if (response !== null && response.data !== undefined && !response.data.errors) {
            if (response.data.data.updateIncidencias.ok) {
                return response.data.data.updateIncidencias.ok;
            }
            else if (response.data.data.updateIncidencias.errors) {
                let errores = response.data.data.updateIncidencias.errors;
                let mensajes = [];

                for (let i = 0; i < errores.length; i++) {
                    let error = errores[i];
                    for (let j = 0; j < error.messages.length; j++) {
                        mensajes.push(error.messages[j]);
                    }
                }
                console.log("lo que tenemos: ", response.data.data.updateIncidencias.errors);
                await store.dispatch('actionsSetErrorMessage', mensajes);
                return null;
            }
        } else if (response.data.errors){
            console.log("lo que tenemos errors: ", response.data.errors[0].message);

            await store.dispatch('actionsSetErrorMessage', response.data.errors[0].message);
            return null;
        }
        else {
            return null;
        }
    }

    static async create(
        axios,
        name,
        role
    ) {
        // let idEmpoyment = Utils.generateString()
        // idEmpoyment: "${idEmpoyment}",

        const response = await axios?.post(
            "/graphql/",
            `mutation{
              createPermission(newPermission:{
                name: "${name}"
                role: ${role}
              }){
                ok
                errors{
                  field
                  messages
                }
                permission{
                  id
                  name
                  role{
                    id
                    name
                    userRole{
                      id
                      username
                      email
                      firstName
                      lastName
                    }
                  }
                }
              }
            }`,
            {
                headers: {
                    "Content-Type": "application/graphql",
                },
            }
        ).catch(function (error) {
            if (error.response) {
                store.dispatch('actionsSetErrorMessage', error.response);
                console.log("primer strike");
                return null;
            } else if (error.request) {
                console.log("segudo strike");
                store.dispatch('actionsSetErrorMessage', error.request);

                return null;
            } else {
                console.log("3er strike");
                store.dispatch('actionsSetErrorMessage', '3er strike');

                return null;
            }
        });
        if (response !== null && response.data !== undefined && !response.data.errors) {
            if (response.data.data.createPermission.ok) {
                return response.data.data.createPermission.ok;
            }
            else if (response.data.data.createPermission.errors) {
                let errores = response.data.data.createPermission.errors;
                let mensajes = [];

                for (let i = 0; i < errores.length; i++) {
                    let error = errores[i];
                    for (let j = 0; j < error.messages.length; j++) {
                        mensajes.push(error.messages[j]);
                    }
                }
                console.log("lo que tenemos: ", response.data.data.createPermission.errors);
                await store.dispatch('actionsSetErrorMessage', mensajes);
                return null;
            }
        } else if (response.data.errors){
            console.log("lo que tenemos errors: ", response.data.errors[0].message);

            await store.dispatch('actionsSetErrorMessage', response.data.errors[0].message);
            return null;
        }
        else {
            return null;
        }
    }
}
