<template>
  <div class="mt-6 ms-10 d-flex justify-content-center align-items-center">
  <div class="container-fluid">
    <div class="card card-body col-8">
      <div class="row gx-4">
        <div class="col-auto">
          <div class="avatar avatar-xl position-relative">
            <img
              :src="getAvatar()"
              alt="profile_image"
              class="shadow-sm w-100 border-radius-lg"
            />
          </div>
        </div>
        <div class="col-auto my-auto">
          <div class="h-100">
            <h5 class="mb-1">{{ getFullName() }}</h5>
          </div>
        </div>
        <div
          class="mx-auto mt-3 col-lg-4 col-md-6 my-sm-auto ms-sm-auto me-sm-0"
        >
          <div class="nav-wrapper position-relative end-0">
            <ul
              class="p-1 bg-transparent nav nav-pills nav-fill"
              role="tablist"
            >
              <li class="nav-item">
                <a
                  class="px-0 py-1 mb-0 nav-link active"
                  data-bs-toggle="tab"
                  href="javascript:;"
                  role="tab"
                  aria-selected="true"
                >
                  <svg
                    class="text-dark"
                    width="16px"
                    height="16px"
                    viewBox="0 0 42 42"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <g
                        transform="translate(-2319.000000, -291.000000)"
                        fill="#FFFFFF"
                        fill-rule="nonzero"
                      >
                        <g transform="translate(1716.000000, 291.000000)">
                          <g transform="translate(603.000000, 0.000000)">
                            <path
                              class="color-background"
                              d="M22.7597136,19.3090182 L38.8987031,11.2395234 C39.3926816,10.9925342 39.592906,10.3918611 39.3459167,9.89788265 C39.249157,9.70436312 39.0922432,9.5474453 38.8987261,9.45068056 L20.2741875,0.1378125 L20.2741875,0.1378125 C19.905375,-0.04725 19.469625,-0.04725 19.0995,0.1378125 L3.1011696,8.13815822 C2.60720568,8.38517662 2.40701679,8.98586148 2.6540352,9.4798254 C2.75080129,9.67332903 2.90771305,9.83023153 3.10122239,9.9269862 L21.8652864,19.3090182 C22.1468139,19.4497819 22.4781861,19.4497819 22.7597136,19.3090182 Z"
                            />
                            <path
                              class="color-background"
                              d="M23.625,22.429159 L23.625,39.8805372 C23.625,40.4328219 24.0727153,40.8805372 24.625,40.8805372 C24.7802551,40.8805372 24.9333778,40.8443874 25.0722402,40.7749511 L41.2741875,32.673375 L41.2741875,32.673375 C41.719125,32.4515625 42,31.9974375 42,31.5 L42,14.241659 C42,13.6893742 41.5522847,13.241659 41,13.241659 C40.8447549,13.241659 40.6916418,13.2778041 40.5527864,13.3472318 L24.1777864,21.5347318 C23.8390024,21.7041238 23.625,22.0503869 23.625,22.429159 Z"
                              opacity="0.7"
                            />
                            <path
                              class="color-background"
                              d="M20.4472136,21.5347318 L1.4472136,12.0347318 C0.953235098,11.7877425 0.352562058,11.9879669 0.105572809,12.4819454 C0.0361450918,12.6208008 6.47121774e-16,12.7739139 0,12.929159 L0,30.1875 L0,30.1875 C0,30.6849375 0.280875,31.1390625 0.7258125,31.3621875 L19.5528096,40.7750766 C20.0467945,41.0220531 20.6474623,40.8218132 20.8944388,40.3278283 C20.963859,40.1889789 21,40.0358742 21,39.8806379 L21,22.429159 C21,22.0503869 20.7859976,21.7041238 20.4472136,21.5347318 Z"
                              opacity="0.7"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                  <span class="ms-1">{{ getRole() }}</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="mt-3 row">
          <div class="col-12 col-md-6 col-xl-6 mt-md-0 mt-6 position-relative">
            <profile-info-card
                title="Información del Perfil"
                :info="{
                fullName: getFullName(),
                mobile: user.phone,
                email: user.email,
                role: getRole(),
                location: 'MX',
              }"
                :social="[
                {
                  link: 'https://www.facebook.com/CreativeTim/',
                  icon: faFacebook,
                },
                {
                  link: 'https://twitter.com/creativetim',
                  icon: faTwitter,
                },
                {
                  link: 'https://www.instagram.com/creativetimofficial/',
                  icon: faInstagram,
                },
              ]"
                :action="{
                route: `#/users/edit-user/${user.id}`,
                tooltip: 'Editar Perfíl',
              }"
            />
            <hr class="vertical dark" />
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import ProfileInfoCard from "./components/ProfileInfoCard.vue";
// import DefaultProjectCard from "./components/DefaultProjectCard.vue";
// import MaterialSwitch from "@/components/MaterialSwitch.vue";
// import MaterialAvatar from "../../../components/MaterialAvatar.vue";
// import sophie from "@/assets/img/kal-visuals-square.jpg";
// import marie from "@/assets/img/marie.jpg";
// import ivana from "@/assets/img/ivana-square.jpg";
// import peterson from "@/assets/img/team-4.jpg";
// import nick from "@/assets/img/team-3.jpg";
// import img1 from "@/assets/img/home-decor-1.jpg";
// import img2 from "@/assets/img/home-decor-2.jpg";
// import img3 from "@/assets/img/home-decor-3.jpg";
// import team1 from "../../../assets/img/team-1.jpg";
// import team2 from "../../../assets/img/team-2.jpg";
// import team3 from "../../../assets/img/team-3.jpg";
// import team4 from "../../../assets/img/team-4.jpg";

import {
  faFacebook,
  faTwitter,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
// import setNavPills from "@/assets/js/nav-pills.js";
// import setTooltip from "@/assets/js/tooltip.js";

export default {
  name: "ProfileOverview",
  components: {
    ProfileInfoCard,
    // DefaultProjectCard,
    // MaterialSwitch,
    // MaterialAvatar,
  },
  data() {
    return {
      user : {},
      showMenu: false,
      faFacebook,
      faTwitter,
      faInstagram,
      url_aws: 'https://s3-integration-store-qa.s3.amazonaws.com'
    };
  },
  watch: {
    '$store.state.currentUser': function () {
      let currentUser = this.$store.getters.getCurrentUserAuth;
      if (
          typeof currentUser === 'object'
          && currentUser !== null
          && currentUser !== 'undefined'
          && currentUser !== undefined
      ) {
        this.user = currentUser;
      } else if (
          typeof currentUser === 'string'
          && currentUser !== 'undefined'
      ) {
        let currentUserJson = JSON.parse(currentUser);
        this.user = currentUserJson;
      } else {
        console.log('No user data available');
        return '';
      }
      console.log("currentUser: ", this.user);
    }
  },
  methods: {
    getAvatar() {
      let currentUser = this.$store.getters.getCurrentUserAuth;
      if (
          typeof currentUser === 'object'
          && currentUser !== null
          && currentUser !== 'undefined'
          && currentUser !== undefined
      ) {
        console.log("avatar url: ", this.url_aws+'/Avatar/'+currentUser.avatar);
        return this.url_aws+'/Avatar/'+currentUser.avatar;
      } else if (
          typeof currentUser === 'string'
          && currentUser !== 'undefined'
      ) {
        let currentUserJson = JSON.parse(currentUser);
        console.log("avatar url: ", this.url_aws+'/Avatar/'+currentUserJson.avatar);
        return this.url_aws+'/Avatar/'+currentUserJson.avatar;
      } else {
        console.log('No user data available');
        return '';
      }
    },
    getFullName(){
      let currentUser = this.$store.getters.getCurrentUserAuth;
      if (
          typeof currentUser === 'object'
          && currentUser !== null
          && currentUser !== 'undefined'
          && currentUser !== undefined
      ) {
        return currentUser.firstName + ' ' + currentUser.lastName;
      } else if (
          typeof currentUser === 'string'
          && currentUser !== 'undefined'
      ) {
        let currentUserJson = JSON.parse(currentUser);
        return currentUserJson.firstName + ' ' + currentUserJson.lastName;

      } else {
        console.log('No user data available');
        return '';
      }
    },
    getRole(){
      let currentUser = this.$store.getters.getCurrentUserAuth;
      if (
          typeof currentUser === 'object'
          && currentUser !== null
          && currentUser !== 'undefined'
          && currentUser !== undefined
      ) {
        return currentUser.compressed['role'].name;
      } else if (
          typeof currentUser === 'string'
          && currentUser !== 'undefined'
      ) {
        let currentUserJson = JSON.parse(currentUser);
        return currentUserJson.compressed['role'].name;

      } else {
        console.log('No user data available');
        return '';
      }
    },
    getPermission(){
      let currentUser = this.$store.getters.getCurrentUserAuth;
      if (
          typeof currentUser === 'object'
          && currentUser !== null
          && currentUser !== 'undefined'
          && currentUser !== undefined
      ) {
        return currentUser.compressed['permission'].map(item => item.name).join(', ');
      } else if (
          typeof currentUser === 'string'
          && currentUser !== 'undefined'
      ) {
        let currentUserJson = JSON.parse(currentUser);
        return currentUserJson.compressed['permission'].map(item => item.name).join(', ');

      } else {
        console.log('No user data available');
        return '';
      }
    }
  },
  async mounted() {
    await this.$store.dispatch('actionsCurrentUser', { 'axios': this.$axios });
    let currentUser = await this.$store.getters.getCurrentUserAuth;
    if (
        typeof currentUser === 'object'
        && currentUser !== null
        && currentUser !== 'undefined'
        && currentUser !== undefined
    ) {
      this.user = currentUser;
    } else if (
        typeof currentUser === 'string'
        && currentUser !== 'undefined'
    ) {
      let currentUserJson = JSON.parse(currentUser);
      this.user = currentUserJson;
    } else {
      console.log('No user data available');
      return '';
    }
    console.log("created: ", this.user);

    // this.$store.state.isAbsolute = true;
    // setNavPills();
    // setTooltip(this.$store.state.bootstrap);
  },
  // beforeUnmount() {
  //   this.$store.state.isAbsolute = false;
  // },
};
</script>
