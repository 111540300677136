import store from "@/store";

export default class Permission {
    static async all(axios) {
        const response = await axios?.post(
            "/graphql/",
            `query{
              permissionList{
                results{
                  id
                  name
                  role{
                    id
                    name
                    userRole{
                      id
                      email
                      username
                      firstName
                      lastName
                    }
                  }
                }
              }
            }`,
            {
                headers: {
                    "Content-Type": "application/graphql",
                },
            }
        );
        return response.data.data?.permissionList.results;
    }

    static async retrieve(axios, id) {
        const response = await axios
            .post(
                "/graphql/",
                `query
                    {
                      PermissionRetrieve(id:${id}){
                        id
                        name
                        role{
                          id
                          name
                          userRole{
                            id
                            email
                            firstName
                            lastName
                            username
                          }
                        }
                      }
                    }`,
                {
                    headers: {
                        "Content-Type": "application/graphql",
                    },
                }
            )
            .catch(function (error) {
                if (error.response) {
                    console.log("primer strike");
                    return null;
                } else if (error.request) {
                    console.log("segudo strike");

                    return null;
                } else {
                    console.log("3er strike");
                    return null;
                }
            });

        if (response) {
            if (
                response.data.data.PermissionRetrieve
            ) {
                response.data.data.PermissionRetrieve.compressed = {
                    users: response.data.data.PermissionRetrieve.role.userRole.map((userRole) => userRole),
                };
                response.data.data.PermissionRetrieve.role = response.data.data.PermissionRetrieve?.role.id
            }
            return response.data.data.PermissionRetrieve;
        }
    }

    static async edit(
        axios,
        id,
        name,
        role
    ) {
        // let idEmpoyment = Utils.generateString()
        // idEmpoyment: "${idEmpoyment}",

        const response = await axios?.post(
            "/graphql/",
            `mutation{
                  updatePermission(newPermission:{
                    id:${id}
                    name:"${name}"
                    role:${role}
                  }){
                    permission{
                      id
                      name
                      role{
                        id
                        name
                      }
                    }
                    ok
                    errors{
                      field
                      messages
                    }
                  }
                }`,
            {
                headers: {
                    "Content-Type": "application/graphql",
                },
            }
        ).catch(function (error) {
            if (error.response) {
                store.dispatch('actionsSetErrorMessage', error.response);
                console.log("primer strike");
                return null;
            } else if (error.request) {
                console.log("segudo strike");
                store.dispatch('actionsSetErrorMessage', error.request);

                return null;
            } else {
                console.log("3er strike");
                store.dispatch('actionsSetErrorMessage', '3er strike');

                return null;
            }
        });
        if (response !== null && response.data !== undefined && !response.data.errors) {
            if (response.data.data.updatePermission.ok) {
                return response.data.data.updatePermission.ok;
            }
            else if (response.data.data.updatePermission.errors) {
                let errores = response.data.data.updatePermission.errors;
                let mensajes = [];

                for (let i = 0; i < errores.length; i++) {
                    let error = errores[i];
                    for (let j = 0; j < error.messages.length; j++) {
                        mensajes.push(error.messages[j]);
                    }
                }
                console.log("lo que tenemos: ", response.data.data.updatePermission.errors);
                await store.dispatch('actionsSetErrorMessage', mensajes);
                return null;
            }
        } else if (response.data.errors){
            console.log("lo que tenemos errors: ", response.data.errors[0].message);

            await store.dispatch('actionsSetErrorMessage', response.data.errors[0].message);
            return null;
        }
        else {
            return null;
        }
    }

    static async create(
        axios,
        name,
        role
    ) {
        // let idEmpoyment = Utils.generateString()
        // idEmpoyment: "${idEmpoyment}",

        const response = await axios?.post(
            "/graphql/",
            `mutation{
              createPermission(newPermission:{
                name: "${name}"
                role: ${role}
              }){
                ok
                errors{
                  field
                  messages
                }
                permission{
                  id
                  name
                  role{
                    id
                    name
                    userRole{
                      id
                      username
                      email
                      firstName
                      lastName
                    }
                  }
                }
              }
            }`,
            {
                headers: {
                    "Content-Type": "application/graphql",
                },
            }
        ).catch(function (error) {
            if (error.response) {
                store.dispatch('actionsSetErrorMessage', error.response);
                console.log("primer strike");
                return null;
            } else if (error.request) {
                console.log("segudo strike");
                store.dispatch('actionsSetErrorMessage', error.request);

                return null;
            } else {
                console.log("3er strike");
                store.dispatch('actionsSetErrorMessage', '3er strike');

                return null;
            }
        });
        if (response !== null && response.data !== undefined && !response.data.errors) {
            if (response.data.data.createPermission.ok) {
                return response.data.data.createPermission.ok;
            }
            else if (response.data.data.createPermission.errors) {
                let errores = response.data.data.createPermission.errors;
                let mensajes = [];

                for (let i = 0; i < errores.length; i++) {
                    let error = errores[i];
                    for (let j = 0; j < error.messages.length; j++) {
                        mensajes.push(error.messages[j]);
                    }
                }
                console.log("lo que tenemos: ", response.data.data.createPermission.errors);
                await store.dispatch('actionsSetErrorMessage', mensajes);
                return null;
            }
        } else if (response.data.errors){
            console.log("lo que tenemos errors: ", response.data.errors[0].message);

            await store.dispatch('actionsSetErrorMessage', response.data.errors[0].message);
            return null;
        }
        else {
            return null;
        }
    }
}
