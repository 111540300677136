<template>
<!--  <navbar btn-background="bg-gradient-success" />-->
  <div
    class="page-header align-items-start min-vh-100"
    style="
      background-image: url('https://images.unsplash.com/photo-1497294815431-9365093b7331?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1950&q=80');
    "
    :data-value="getToken"
  >
    <span class="mask bg-gradient-dark opacity-6"></span>
    <div class="container my-auto" :data-value="getErrorMessage">
      <div class="row">
        <div class="col-lg-4 col-md-8 col-12 mx-auto">
          <div class="card z-index-0 fadeIn3 fadeInBottom">
            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <div class="bg-gradient-success shadow-success border-radius-lg py-3 pe-1 d-flex flex-column align-items-center">
                <img :src="sidebarType === 'bg-white' || (sidebarType === 'bg-transparent' && !isDarkMode)
                ? logoDark
                : logo
            "
                    class="navbar-brand-img h-100"
                    alt="main_logo"
                />
                <h4 class="text-white font-weight-bolder text-center mt-2 mb-0">
                  Iniciar Sesión
                </h4>
              </div>
            </div>
            <div class="card-body">
              <form role="form" class="text-start mt-3" @submit.prevent="submit">
                <div v-if="error !== ''">
                  <label
                      type="error"
                      :value="alert"
                      class="alert-warning text-bold"
                  >
                    {{ error }}
                  </label>
                </div>
                <div class="mb-3">
                  <label class="form-label col-sm-12">
                    <input
                        id="username"
                        v-model="username"
                        type="text"
                        placeholder=" "
                        class="form-control"
                        name="username"
                        required
                        @input="validateUsername"
                    />
                    <span>Nombre de Usuario *</span>
                    <p v-if="errors.username">{{ errors.username }}</p>
                  </label>
                </div>
                <div class="mb-3">
                  <label class="form-label col-sm-12">
                    <input
                        :type="showPassword ? 'text' : 'password'"
                        id="password"
                        v-model="password"
                        placeholder=" "
                        class="form-control"
                        name="password"
                        required
                        @input="validatePassword"
                        @keydown.enter="submit"
                    />
                    <i :class="showPassword ? 'fa fa-eye-slash' : 'fa fa-eye'" @click="showPassword = !showPassword" style="position: absolute; right: 10px; top: 10px;"></i>
                    <span>Contraseña *</span>
                  </label>
                  <i :class="showPassword ? 'fa fa-eye-slash' : 'fa fa-eye'" @click="showPassword = !showPassword" style="position: absolute; right: 10px; top: 10px;"></i>
                  <p v-if="errors.password">{{ errors.password }}</p>
                </div>
                <div class="text-center">
                  <material-button
                    class="my-4 mb-2"
                    variant="gradient"
                    color="success"
                    full-width
                    @keydown.enter="submit"
                    submit
                    @click="submit"
                    >Entrar</material-button
                  >
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
  <footer class="footer">
    <div class="footer-content">
      ©
      S3 Integración.
      <!--      {{ new Date().getFullYear() }} -->
      Todos los derechos reservados. Desarrollado por
      <a
          href="https://www.idoogroup.com"
          class="font-weight-bold"
          target="_blank"
      >
        Idoogroup.
      </a>
    </div>
  </footer>
</template>

<script>
// import MaterialInput from "@/components/MaterialInput.vue";
import MaterialButton from "@/components/MaterialButton.vue";
import { mapMutations } from "vuex";
import logo from "@/assets/img/logo-ct.png";
import logoDark from "@/assets/img/logo-ct-dark.png";
export default {
  name: "SigninBasic",
  components: {
    // MaterialInput,
    MaterialButton,
  },

  data() {
    return {
      logo,
      logoDark,
      alert: false,
      password: "",
      username: "",
      showPassword: false,
      errors: {
        password: "",
        username: ""
      }
    };
  },
  beforeMount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  methods: {
    validatePassword() {
      if (this.password){
        this.errors.password = this.password.length < 8 ? "La contraseña debe tener al menos 8 caracteres." : "";
      } else {
        this.errors.password = "La contraseña es requerida"
      }
    },
    validateUsername() {
      if (!this.username){
        this.errors.username = "Nombre de usuario requerido";
      }
    },
    // validateUsername() {
    //   if (this.username){
    //     const regex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
    //     this.errors.username = regex.test(this.username) ? "" : "Correo Electronico Invalido";
    //   } else {
    //     this.errors.username = "Correo Electronico requerido";
    //   }
    //
    // },
    submit() {
      this.validatePassword();
      this.validateUsername();
      if (!this.errors.password && !this.errors.username) {
        // console.log("Form submitted:", { password: this.password, username: this.username });
        this.$store.dispatch('actionsLogin', {
          'axios': this.$axios,
          'username': this.username,
          'password': this.password,
          'store': this.$store
        });
      }
    },
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),

    beforeLogin() {
      this.$router.push({ path: "/dashboards/dashboard-default" });
    }
  },
  computed:{
    getErrorMessage() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.error = this.$store.getters.getErrorMessage
      if (this.error !== '' && this.error !== null) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.alert = true
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.loading = false
      } else {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.loading = false
      }
      return this.error
    },
    getToken() {
      if (
          this.$store.getters.getToken !== null
          && this.$store.getters.getToken !== 'null'
          && this.$store.getters.getToken !== 'null-new'
      ) {
        this.$store.dispatch('actionsMe', { 'axios': this.$axios });
        this.$store.dispatch('actionsCurrentUser', { 'axios': this.$axios });
        this.beforeLogin()
      } else {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.username = ''
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.password = ''
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.loading = false
      }
      return this.$store.getters.getToken
    },
  },
  created() {
    this.$store.state.showFooter = true;
  }
};
</script>
<style scoped>
.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  color: white;
  text-align: center;
}

.footer-content {
  padding: 20px;
}

.form-label {
  position: relative;
  display: inline-block;
}
.form-label span {
  position: absolute;
  left: 5px;
  top: 0;
  transition: 0.2s;
  pointer-events: none;
}

.form-label input:focus + span,
.form-label input:not(:placeholder-shown) + span {
  transform: translateY(-15px);
  font-size: 0.8em;
}
</style>
