<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-12">
        <div class="multisteps-form mb-9">
          <div class="row">
            <div class="col-12 col-lg-8 mx-auto my-5"></div>
          </div>
          <div class="row">
            <div class="col-12 col-lg-8 m-auto">
              <div class="card">
                <div
                    class="card-header p-0 position-relative mt-n4 mx-3 z-index-2"
                >
                  <div
                      class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
                  >
                    <div class="multisteps-form__progress">
                      <button
                          class="multisteps-form__progress-btn"
                          type="button"
                          title="Crear Perfil de Impresión"
                          :class="activeStep >= 0 ? activeClass : ''"
                      >
                        <span>Crear Perfil de Impresión</span>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="card-body">
                  <form class="multisteps-form__form">
                    <!--single form panel-->
                    <h5 class="font-weight-bolder mb-0">Información del Perfil de Impresión</h5>
                    <div class="multisteps-form__content">
                      <div class="row mt-5">
                        <div class="col-12 col-sm-6">
                          <label class="form-label col-sm-12">
                            <input
                                id="name"
                                v-model="item.name"
                                placeholder=" "
                                type="text"
                                class="form-control border-bottom"
                                name="name"
                                required
                            />
                            <span>Nombre *</span>
                          </label>
                        </div>
                        <div class="col-12 col-sm-6">
                          <label class="form-label col-sm-12">
                            <input
                                id="labelSize"
                                v-model="item.labelSize"
                                placeholder=" "
                                type="text"
                                class="form-control border-bottom"
                                name="labelSize"
                                required
                            />
                            <span>Tamaño Etiqueta, Ejemplo: 28x89 *</span>
                          </label>
                        </div>
                      </div>
                      <div class="row mt-3">
                        <div class="col-12 col-sm-6">
                          <label class="form-label col-sm-12">
                            <input
                                id="leafMargins"
                                v-model="item.leafMargins"
                                placeholder=" "
                                type="text"
                                class="form-control border-bottom"
                                name="leafMargins"
                                required
                            />
                            <span>Márgenes de la Hoja, Ejemplo: 0.1,0.1 *</span>
                          </label>
                        </div>
                        <div class="col-12 col-sm-6">
                          <label class="form-label col-sm-12">
                            <input
                                id="separationBetweenLabels"
                                v-model="item.separationBetweenLabels"
                                placeholder=" "
                                type="number"
                                class="form-control border-bottom"
                                name="separationBetweenLabels"
                                required
                            />
                            <span>Separación entre etiquetas, Ejemplo: 0.1,0.1 *</span>
                          </label>
                        </div>
                      </div>
                      <div class="row mt-3">
                        <div class="col-12 col-sm-6">
                          <label class="form-label col-sm-12">
                            <select
                                id="sheetPosition"
                                class="form-control border-bottom"
                                v-model="item.sheetPosition"
                                name="sheetPosition"
                            >
                              <option v-for="(sp, index) in sheetPosition" :key="index" :value="sp">{{ sp }}</option>
                            </select>
                            <span>Posición de la Hoja *</span>
                          </label>
                        </div>
                        <div class="col-12 col-sm-6">
                          <label class="form-label col-sm-12">
                            <input
                                id="sheetSize"
                                v-model="item.sheetSize"
                                name="sheetSize"
                                placeholder=" "
                                type="number"
                                class="form-control border-bottom"
                                required
                            />
                            <span>Tamaño de la Hoja, Ejemplo: 28x89 *</span>
                          </label>
                        </div>
                      </div>
                      <div class="row mt-3">
                        <div class="col-12 col-sm-8">
                          <label class="form-label col-sm-12">
                            <select
                                id="user"
                                v-model="item.user"
                                class="form-control border-bottom custom-select"
                                name="user"
                                required
                            >
                              <option v-for="(user, index) in users" :key="index" :value="user.id">{{ user.username }}</option>
                            </select>
                            <span>Usuario *</span>
                          </label>
                        </div>
                      </div>
                      <div v-if="error !== ''">
                        <label
                            type="error"
                            :value="alert"
                            class="alert-warning text-bold"
                        >
                          {{ error }}
                        </label>
                      </div>
                      <div class="row" v-if="ok != null">
                        <material-alert
                            border="top"
                            close-icon="$cancel"
                            :color="colorAlert()"
                            transition="scale-transition"
                            :value="ok"
                            dismissible
                        >
                          {{ textAlert() }}
                        </material-alert>
                      </div>
                      <div class="button-row d-flex mt-4">
                        <material-button
                            type="button"
                            color="danger"
                            variant="danger"
                            class="js-btn-send"
                            @click="getRoutePrintingProfileList()"
                        >Cancelar</material-button
                        >
                        <material-button
                            type="button"
                            color="success"
                            variant="gradient"
                            class="ms-auto js-btn-send"
                            @click="createPrintingProfile()"
                        >Aceptar</material-button
                        >
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialAlert from "@/components/MaterialAlert.vue";


export default {
  name: "NewPrintingProfile",
  components: {
    MaterialAlert,
    MaterialButton,
  },
  data() {
    return {
      showMenu: false,
      activeClass: "js-active position-relative",
      activeStep: 0,
      formSteps: 3,
      error: '',
      ok: null,
      alert: false,
      users: [],
      sheetPosition: {
        VERICAL: 'VERTICAL',
        HORIZONTAL: 'HORIZONTAL'
      },
      sheetSizes: {
        A4: 'A4',
        CARTA: 'CARTA',
        OFICIO: 'OFICIO'
      },
      item: {
        name: '',
        labelSize: '',
        leafMargins: '',
        separationBetweenLabels: '',
        sheetPosition: '',
        sheetSize: '',
        user: null,
      }
    };
  },
  watch :{
    '$store.state.ok': function (value) {
      console.log("state.ok: ", value)
      if (value){
        this.ok = true;
        setTimeout(this.getRoutePrintingProfileList, 100);
      }
    },
    '$store.state.errorMessage': function (message) {
      this.error = message;
      this.alert = true;
    },
    '$store.state.usersList': function (users) {
      this.users = users;
    },
  },
  methods: {
    getRoutePrintingProfileList(){
      this.$router.push({ path: "/printingProfile/list" });
    },
    createPrintingProfile() {
      this.$store.dispatch('actionsCreatePrintingProfile', {
        axios: this.$axios,
        id: this.item.id,
        name: this.item.name,
        sheetSize: this.item.sheetSize,
        labelSize: this.item.labelSize,
        leafMargins: this.item.leafMargins,
        sheetPosition: this.item.sheetPosition,
        separationBetweenLabels: this.item.separationBetweenLabels,
        user: this.item.user,
      });
    },
    textAlert() {
      return this.ok
          ? "Perfil de Impresión creado satisfactoriamiente"
          : "Error al intentar crear el Perfil de Impresión";
    },
    colorAlert() {
      return this.ok ? "success" : "danger";
    },
  },
  async created() {
    this.$store.dispatch('actionsGetAllUsers', {'axios': this.$axios});
    this.users = await this.$store.getters.getAllUsers;
  }
};
</script>
<style>
.form-label {
  position: relative;
  display: inline-block;
}


.form-label span {
  position: absolute;
  left: 5px;
  top: 0;
  transition: 0.2s;
  pointer-events: none;
}

.form-label input:focus + span,
.form-label input:not(:placeholder-shown) + span {
  transform: translateY(-5px);
  font-size: 0.8em;
}
.custom-select {
  appearance: none;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4 5"><path fill="%23aaa" d="M2 0L0 2h4zm0 5L0 3h4z"/></svg>');
  background-repeat: no-repeat;
  background-position: right .75rem center;
  background-size: 8px 10px;
}
</style>
