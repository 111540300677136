<template>
  <div v-if="isLoading" class="loading-overlay">
    <div class="loading-spinner"></div>
  </div>
  <div class="container-fluid py-4">
    <div class="d-sm-flex justify-content-end">
      <div class="d-flex ">
        <material-button
            class="btn-icon ms-2"
            size=""
            color="success"
            variant="gradient"
            data-type="csv"
            @click="exportarPDF"
        >
          <span class="btn-inner--text">Exportar PDF</span>
        </material-button>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card mt-4">
          <div class="card-header">
            <h5 class="mb-0">Listado de Impresiones</h5>
          </div>
          <div class="table-responsive">
            <div v-if="error !== ''">
              <label
                  type="error"
                 :value="alert"
                  class="alert-warning text-bold"
              >
                {{ error }}
              </label>
            </div>
            <div v-if="message !== ''" class="bg-gradient-light">
              <label
                  type="success"
                  :value="show_text"
              >
                {{ message }}
              </label>
            </div>
            <div class="table-responsive">
              <table id="impresiones-list" class="table table-flush">
                <thead class="thead-light">
                <tr>
<!--                  <th>Id</th>-->
                  <th>Fecha</th>
                  <th>Usuario</th>
                  <th>Total Impresiones</th>
                  <th>No. ASIN Órdenes</th>
                  <th>PDF</th>
<!--                  <th>Acción</th>-->
                </tr>
                </thead>
                <tbody>
                <tr v-for="item in items" :key="item.id">
<!--                  <td>{{ item.id }}</td>-->
                  <td>{{ dateHumanization(item.date) }}</td>
                  <td v-if="item.user">{{ item.user?.username }}</td>
                  <td v-else>No Tiene</td>
                  <td>{{ item.total }}</td>
                  <td v-if="item.order">{{ item.order }}</td>
                  <td v-else>No Tiene</td>
                  <td v-if="item.pdf">
                    <a v-if="item.pdf" :href="downloadUrl(item)" target="_blank">Descargar PDF</a>
                  </td>
                  <td v-else>No Tiene</td>


<!--                  <td>-->
<!--                    <chip-card v-if="item.status === 'DENEGADA'" color="red" text="Denegada" />-->
<!--                    <chip-card v-else-if="item.status === 'APROBADA'" color="green" text="Aprobada" />-->
<!--                    <chip-card v-else color="blue" text="Pendiente" />-->
<!--                  </td>-->
<!--                  <td>-->
<!--                    <router-link class="action-link" :to="`/report/incidencias/edit/${item.id}`">-->
<!--                      <i class="fas fa-pencil-alt action color-accent" />-->
<!--                    </router-link>-->
<!--&lt;!&ndash;                    <router-link class="action-link" to="">&ndash;&gt;-->
<!--&lt;!&ndash;                      <i class="fas fa-trash action color-accent" @click="deleteItemWrapper(item.id, $event)"/>&ndash;&gt;-->
<!--&lt;!&ndash;                    </router-link>&ndash;&gt;-->
<!--                  </td>-->

                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { DataTable } from "simple-datatables";
import Utils from "@/utils/Utils";
import moment from "moment/moment";
import MaterialButton from "@/components/MaterialButton.vue";
import logo from '@/assets/img/logo.png';

export default {
  name: "ImpresionesList",
  components: {
    MaterialButton
    // ChipCard

  },
  data: () => ({
    fechaActual: new Date().toLocaleDateString(),
    isLoading: false,
    datatable: null,
    itemsUpload: [],
    showTable: true,
    url_aws: 'https://s3-integration-store-qa.s3.amazonaws.com',
    items: [],
    textConfirmDelete: "¿Está seguro que desea eliminar la Impresion?",
    error: '',
    message: '',
    show_text: false,
    alert: false,
  }),
  watch: {
    '$store.state.impresionesList': function (impresiones) {
      this.items = impresiones;
      console.log("items: ", this.items);
      if (this.datatable) {
        this.datatable.destroy();
      }
      this.$nextTick(() => {
        this.datatable = new DataTable("#impresiones-list", {
          searchable: true,
          fixedHeight: false,
          perPage: 15,
          perPageSelect: [15, 20, 25, 'Todos'],
          labels: {
            placeholder: "Buscar...", // Placeholder del campo de búsqueda
            perPage: "{select} registros por página", // Entradas por página
            noRows: "No hay registros", // Mensaje cuando no hay entradas
            noResults: "No se encontraron registros",// Mensaje cuando no hay entradas
            info: "Mostrando {start} a {end} de {rows} entradas", // Información de las entradas mostradas
          }
        });

        document.querySelector('.dataTable-dropdown').addEventListener('change', (event) => {
          if (event.target.value === "Todos") {
            console.log("mostrando todos:", impresiones.length);
            this.datatable.options.perPage = impresiones.length;
            this.datatable.update();
          }
        });
        let inputElement = document.querySelector(".dataTable-search input");
        let iconElement = document.createElement("i");
        iconElement.className = "fas fa-search";
        inputElement.parentNode.appendChild(iconElement);
        inputElement.placeholder = "Buscar...";
        inputElement.parentNode.style.position = "relative";
        iconElement.style.position = "absolute";
        iconElement.style.top = "50%";
        iconElement.style.right = "10px";
        iconElement.style.transform = "translateY(-50%)";

        let dropdownElement = document.querySelector(".dataTable-dropdown");
        let infoElement = document.querySelector(".dataTable-info");
        let paginationElement = document.querySelector(".dataTable-pagination");
        let bottomElement = document.querySelector(".dataTable-bottom");
        dropdownElement.parentNode.removeChild(dropdownElement);
        infoElement.parentNode.removeChild(infoElement);
        paginationElement.parentNode.removeChild(paginationElement);
        bottomElement.appendChild(infoElement);
        bottomElement.appendChild(dropdownElement);

        bottomElement.appendChild(paginationElement);
        bottomElement.style.display = "flex";
        bottomElement.style.justifyContent = "space-between";

      }).then(() => {
        this.isLoading = false;
      });
    },
  },

  methods: {
    downloadUrl(value) {
      if (value.pdf) {
        return this.url_aws + '/pdf/' + value.pdf;
      }
      return '#';
    },
    exportarPDF() {
      fetch(logo)
          .then(response => response.blob())
          .then(blob => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () => {
              const base64logo = reader.result;
              var docDefinition = {
                content: [
                  {
                    image: base64logo,
                    width: 150,
                    alignment: 'center'
                  },
                  { text: '', alignment: 'center'},
                  { text: 'Fecha del Reporte: ' + this.fechaActual, alignment: 'right'},
                  { text: 'Impresiones', style: 'header' },
                  {
                    style: 'tableExample',
                    table: {
                      body: [
                        ['ID', 'Total de Impresiones', 'Fecha', 'ASIN Órdenes', 'Usuario'],
                        ...this.items.map(item => [
                          item.id || 'No Tiene',
                          item.total || 'No Tiene',
                          this.dateHumanization(item.date) || 'No Tiene',
                          item.order || 'No Tiene',
                          item.user || 'No Tiene'
                        ]),
                      ]
                    }
                  },
                ],
                styles: {
                  header: {
                    fontSize: 18,
                    bold: true,
                    margin: [0, 0, 0, 10]
                  },
                  tableExample: {
                    margin: [0, 5, 0, 15]
                  }
                }
              }

              this.$pdfMake.createPdf(docDefinition).download('Impresiones.pdf');
            };
          });
    },
    getUrl(foto){
      return this.url_aws+'/Etiquetas/'+foto
    },
    openModal(foto) {
      this.showModal = true;
      this.selectedFoto = foto;
      console.log("aca estamos");
    },
    closeModal() {
      this.showModal = false;
    },
    dividirString(fotos) {
      return fotos.split(' | ');
    },
    buildTableBody(data, columns) {
      console.log("data: ", data);
      console.log("columns: ", columns);
      let body = [];
      body.push(columns);
      data.forEach(function(row) {
        let dataRow = [];
        columns.forEach(function(column) {
          // Verifica si el nombre de la columna es 'date'
          if (column === 'date') {
            // Aplica el tratamiento a la fecha, por ejemplo, formateándola
            moment.locale('es');
            let date = moment(row[column]).format('D MMMM YYYY');
            dataRow.push(date.toString());
          } else {
            dataRow.push(row[column].toString());
          }
        })
        body.push(dataRow);
      });
      return body;
    },
    table(data, columns) {
      return {
        table: {
          headerRows: 1,
          body: this.buildTableBody(data, columns)
        }
      };
    },
    onGenPDF () {
      let docDefinition = {
        content: [
          this.table(this.items, ['id', 'total', 'date', 'order'])
        ]
      };
      this.pdfmake.createPdf(docDefinition).download();
    },
    dateHumanization(date) {
      return Utils.dateHumanization(date);
    },
  },
  async mounted() {
    this.isLoading = true;
    this.$store.dispatch('actionsGetAllImpressions', {'axios': this.$axios});
    this.items = await this.$store.getters.getAllImpresiones;
    this.$store.state.ok = false;
    },
};
</script>
<style scoped>
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loading-spinner {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s cubic-bezier(0.68, -0.55, 0.27, 1.55) infinite; /* Safari */
  animation: spin 2s cubic-bezier(0.68, -0.55, 0.27, 1.55) infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.action-link {
  margin-right: 10px; /* Ajusta este valor según tus necesidades */
}
</style>
