import store from "@/store";

export default class Area {
    static async all(axios) {
        const response = await axios?.post(
            "/graphql/",
            `query{
              areaList{
                results{
                  id
                  name
                  userArea{
                    username
                    email
                  }
                }
              }
            }`,
            {
                headers: {
                    "Content-Type": "application/graphql",
                },
            }
        );
        console.log("areas: ", response.data.data?.areaList.results);
        return response.data.data?.areaList.results;
    }

    static async retrieve(axios, id) {
        const response = await axios
            .post(
                "/graphql/",
                `query{
                  areaRetrieve(id:${id}){
                    id
                    name
                    userArea{
                      id
                      username
                      email
                    }
                  }
                }`,
                {
                    headers: {
                        "Content-Type": "application/graphql",
                    },
                }
            )
            .catch(function (error) {
                if (error.response) {
                    console.log("primer strike");
                    return null;
                } else if (error.request) {
                    console.log("segudo strike");

                    return null;
                } else {
                    console.log("3er strike");
                    return null;
                }
            });

        if (response) {
            if (
                response.data.data.areaRetrieve
            ) {
                response.data.data.areaRetrieve.compressed = {
                    permission: response.data.data.areaRetrieve.userArea.map((userArea) => userArea),
                };
            }
            return response.data.data.areaRetrieve;
        }
    }
    static async edit(
        axios,
        id,
        name,

    ) {

        const response = await axios?.post(
            "/graphql/",
            `mutation{
                  updateArea(newArea:{
                    id: ${id}
                    name: "${name}"
                  }){
                    area{
                      id
                      name
                    }
                    ok
                    errors{
                      field
                      messages
                    }
                  }
                }`,
            {
                headers: {
                    "Content-Type": "application/graphql",
                },
            }
        ).catch(function (error) {
            if (error.response) {
                store.dispatch('actionsSetErrorMessage', error.response);
                console.log("primer strike");
                return null;
            } else if (error.request) {
                console.log("segudo strike");
                store.dispatch('actionsSetErrorMessage', error.request);

                return null;
            } else {
                console.log("3er strike");
                store.dispatch('actionsSetErrorMessage', '3er strike');

                return null;
            }
        });
        if (response !== null && response.data !== undefined && !response.data.errors) {
            if (response.data.data.updateArea.ok) {
                return response.data.data.updateArea.ok;
            }
            else if (response.data.data.updateArea.errors) {
                let errores = response.data.data.updateArea.errors;
                let mensajes = [];

                for (let i = 0; i < errores.length; i++) {
                    let error = errores[i];
                    for (let j = 0; j < error.messages.length; j++) {
                        mensajes.push(error.messages[j]);
                    }
                }
                console.log("lo que tenemos: ", response.data.data.updateArea.errors);
                await store.dispatch('actionsSetErrorMessage', mensajes);
                return null;
            }
        } else if (response.data.errors){
            console.log("lo que tenemos errors: ", response.data.errors[0].message);

            await store.dispatch('actionsSetErrorMessage', response.data.errors[0].message);
            return null;
        }
        else {
            return null;
        }
    }

    static async create(
        axios,
        name,

    ) {

        const response = await axios?.post(
            "/graphql/",
            `mutation{
              createArea(newArea:{
                name: "${name}"
              }){
                ok
                errors{
                  field
                  messages
                }
                area{
                  id
                  name
                  userArea{
                    id
                    username
                    email
                  }
                }
              }
            }`,
            {
                headers: {
                    "Content-Type": "application/graphql",
                },
            }
        ).catch(function (error) {
            if (error.response) {
                store.dispatch('actionsSetErrorMessage', error.response);
                console.log("primer strike");
                return null;
            } else if (error.request) {
                console.log("segudo strike");
                store.dispatch('actionsSetErrorMessage', error.request);

                return null;
            } else {
                console.log("3er strike");
                store.dispatch('actionsSetErrorMessage', '3er strike');

                return null;
            }
        });
        if (response !== null && response.data !== undefined && !response.data.errors) {
            if (response.data.data.createArea.ok) {
                return response.data.data.createArea.ok;
            }
            else if (response.data.data.createArea.errors) {
                let errores = response.data.data.createArea.errors;
                let mensajes = [];

                for (let i = 0; i < errores.length; i++) {
                    let error = errores[i];
                    for (let j = 0; j < error.messages.length; j++) {
                        mensajes.push(error.messages[j]);
                    }
                }
                console.log("lo que tenemos: ", response.data.data.createArea.errors);
                await store.dispatch('actionsSetErrorMessage', mensajes);
                return null;
            }
        } else if (response.data.errors){
            console.log("lo que tenemos errors: ", response.data.errors[0].message);

            await store.dispatch('actionsSetErrorMessage', response.data.errors[0].message);
            return null;
        }
        else {
            return null;
        }
    }

    static async delete(axios, id) {
        const response = await axios.post(
            "/graphql/",
            {
                query: `mutation deleteArea ($id: ID!){
                      deleteArea(id: $id){
                        ok
                      }
                    }`,
                variables: {
                    id: id,
                },
            },
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );

        return response.data.data.deleteArea.ok;
    }
}
