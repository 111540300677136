export default class Auth {
  static async login(axios, username, password, store) {
    const response = await axios.post(
        '/graphql/',
        `mutation{
            tokenAuth(
              username:"${username}"
              password:"${password}"
            ){
              token
              refreshToken
              success
              errors
              user{
                id
                pk
                username
                email
              }
            }
          }`,
      {
        headers: {
          'Content-Type': 'application/graphql',
        }
      }
    ).catch(function (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        // console.log("hay error aca: ",error.response);
        // console.log(error.response.status);
        // console.log(error.response.headers);

        // if (error.response.status === 400) {
        console.log("error.response: ", error.response)
        store.dispatch('actionsSetErrorMessage', 'Usuario o contraseña incorrectos');
        // }
        return null
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log("ErrorRequest: ", error.request);
        store.dispatch('actionsSetErrorMessage', 'Usuario o contraseña incorrectos');
        return null
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error.message', error.message);
        store.dispatch('actionsSetErrorMessage', 'Usuario o contraseña incorrectos');
        return null
      }
    });

    if (response !== null && response.data !== undefined ) {
      // console.log('el token es...!!!', response.data.data.tokenAuth.token);z

      if (response.data.data.tokenAuth.success){
        return response.data.data.tokenAuth;
      } else {
        store.dispatch('actionsSetErrorMessage', response.data.data.tokenAuth.errors.nonFieldErrors[0].message);
        console.log('el mensaje de error..!!', response.data.data.tokenAuth)
        return null
      }
    } else {
      return null;
    }

  }

  static async logout(axios, refreshToken, store) {
    const response = await axios.post(
        '/graphql/',
        `  mutation{
            revokeToken(
                refreshToken:"${refreshToken}"
            ){
              revoked
              success
              errors
            }
          }`,
        {
          headers: {
            'Content-Type': 'application/graphql',
          }
        }
    ).catch(function (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);

        if (error.response.status === 400) {
          store.dispatch('actionsSetErrorMessage', error.response);
        }
        return ''
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
        store.dispatch('actionsSetErrorMessage', error.request);

      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
        store.dispatch('actionsSetErrorMessage', error.message);
      }
    });

    // console.log(response.data);
    return response.data;
  }

  static async me(axios) {
    const response = await axios.post(
        '/graphql/',
        `query{
              me {
                id
                avatar
                username
                firstName
                lastName
                email
                role{
                  id
                  name
                }
          }
          }`,
      {
        headers: {
          'Content-Type': 'application/graphql'
        }
      }
    ).catch(function (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        // console.log(error.response.data);
        // console.log(error.response.status);
        // console.log(error.response.headers);

        // if (error.response.status === 400) {
        // store.dispatch('actionsSetErrorMessage', 'Usuario o contraseña incorrectos');
        // }
        // console.log('primer strike')
        return null
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        // console.log(error.request);
        // store.dispatch('actionsSetErrorMessage', 'Usuario o contraseña incorrectos');
        // console.log('segudo strike')

        return null
      } else {
        // Something happened in setting up the request that triggered an Error
        // console.log('Error', error.message);
        // store.dispatch('actionsSetErrorMessage', 'Usuario o contraseña incorrectos');
        // console.log('3er strike');

        return null
      }
    });
    // console.log('lo que viene en el data...', response.data.data);
    return response.data.data.me
  }

}
