<template>
  <div v-if="isLoading" class="loading-overlay">
    <div class="loading-spinner"></div>
  </div>
  <div class="container-fluid py-4">
    <div class="d-sm-flex justify-content-end">
      <div class="d-flex ">
        <material-button
            class="btn-icon ms-2"
            size=""
            color="success"
            variant="gradient"
            data-type="csv"
            @click="exportarPDF"
        >
          <span class="btn-inner--text">Exportar PDF</span>
        </material-button>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card mt-4">
          <div class="card-header">
            <h5 class="mb-0">Listado de Incidencias</h5>
          </div>
          <div class="table-responsive">
            <div v-if="error !== ''">
              <label
                  type="error"
                 :value="alert"
                  class="alert-warning text-bold"
              >
                {{ error }}
              </label>
            </div>
            <div v-if="message !== ''" class="bg-gradient-light">
              <label
                  type="success"
                  :value="show_text"
              >
                {{ message }}
              </label>
            </div>
            <div class="table-responsive">
              <table id="incidencias-list" class="table table-flush">
                <thead class="thead-light">
                <tr>
                  <th>Id</th>
                  <th>Fecha</th>
                  <th>Descripción</th>
                  <th>Usuario</th>
                  <th>No. ASIN Órden</th>
                  <th>Fotos</th>
                  <th>Estado</th>
                  <th>Acción</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="item in items" :key="item.id">
                  <td>{{ item.id }}</td>
                  <td>{{ dateHumanization(item.date) }}</td>
                  <td>{{ item.descripcion }}</td>
                  <td>{{ item.user.username }}</td>
                  <td>{{ item.order.asin }}</td>
                  <td>
                    <a v-for="(foto, index) in dividirString(item.fotos)"
                       :key="index"
                       @click="openModal(foto)">
                      <img :data-item-id="item.id" :data-foto-index="index" class="shadow-sm w-100 border-radius-lg avatar avatar-xl position-relative my-image"
                           :src="this.url_aws+'/Incidencias/'+foto" alt="Fotos"/>
                    </a>
                  </td>
                  <td>
                    <chip-card v-if="item.status === 'DENEGADA'" color="red" text="Denegada" />
                    <chip-card v-else-if="item.status === 'APROBADA'" color="green" text="Aprobada" />
                    <chip-card v-else color="blue" text="Pendiente" />
                  </td>
                  <td>
                    <router-link class="action-link" :to="`/report/incidencias/edit/${item.id}`">
                      <i class="fas fa-pencil-alt action color-accent" />
                    </router-link>
                    <router-link class="action-link" :to="`/report/incidencias/view/${item.id}`">
                      <i class="fas fa-eye action color-accent"/>
                    </router-link>
                  </td>
                </tr>
                </tbody>
              </table>
              <div v-if="showModal" class="modal">
                <div class="modal-content">
                  <span class="close-button" @click="closeModal">×</span>
                  <img :src="getUrl(selectedFoto)" alt="Foto grande" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { DataTable } from "simple-datatables";
import Utils from "@/utils/Utils";
import ChipCard from "@/views/ecommerce/components/ChipCard.vue";
import MaterialButton from "@/components/MaterialButton.vue";
import logo from "@/assets/img/logo.png";
// import logo from '@/assets/img/logo.png';

export default {
  name: "IncidenciasList",
  components: {
    MaterialButton,
    ChipCard,
  },
  data: () => ({
    showModal: false,
    selectedFoto: null,
    fechaActual: new Date().toLocaleDateString(),
    isLoading: false,
    datatable: null,
    itemsUpload: [],
    showTable: true,
    items: [],
    textConfirmDelete: "¿Está seguro que desea eliminar el Area?",
    url_aws: 'https://s3-integration-store-qa.s3.amazonaws.com',
    error: '',
    message: '',
    show_text: false,
    alert: false,
  }),
  watch: {
    '$store.state.incidenciasList': function (incidencias) {
      this.items = incidencias;
      console.log("items: ", this.items);
      if (this.datatable) {
        this.datatable.destroy();
      }
      this.$nextTick(() => {
        this.datatable = new DataTable("#incidencias-list", {
          searchable: true,
          fixedHeight: false,
          perPage: 15,
          perPageSelect: [15, 20, 25, 'Todos'],
          labels: {
            placeholder: "Buscar...", // Placeholder del campo de búsqueda
            perPage: "{select} registros por página", // Entradas por página
            noRows: "No hay registros", // Mensaje cuando no hay entradas
            noResults: "No se encontraron registros",// Mensaje cuando no hay entradas
            info: "Mostrando {start} a {end} de {rows} entradas", // Información de las entradas mostradas
          }
        });

        document.querySelector('.dataTable-dropdown').addEventListener('change', (event) => {
          if (event.target.value === "Todos") {
            console.log("mostrando todos:", incidencias.length);
            this.datatable.options.perPage = incidencias.length;
            this.datatable.update();
          }
        });
        let inputElement = document.querySelector(".dataTable-search input");
        let iconElement = document.createElement("i");
        iconElement.className = "fas fa-search";
        inputElement.parentNode.appendChild(iconElement);
        inputElement.placeholder = "Buscar...";
        inputElement.parentNode.style.position = "relative";
        iconElement.style.position = "absolute";
        iconElement.style.top = "50%";
        iconElement.style.right = "10px";
        iconElement.style.transform = "translateY(-50%)";

        let dropdownElement = document.querySelector(".dataTable-dropdown");
        let infoElement = document.querySelector(".dataTable-info");
        let paginationElement = document.querySelector(".dataTable-pagination");
        let bottomElement = document.querySelector(".dataTable-bottom");
        dropdownElement.parentNode.removeChild(dropdownElement);
        infoElement.parentNode.removeChild(infoElement);
        paginationElement.parentNode.removeChild(paginationElement);
        bottomElement.appendChild(infoElement);
        bottomElement.appendChild(dropdownElement);

        bottomElement.appendChild(paginationElement);
        bottomElement.style.display = "flex";
        bottomElement.style.justifyContent = "space-between";

      }).then(() => {
        this.isLoading = false;
      });
    },
  },
  async mounted() {
    this.isLoading = true;
    this.$store.dispatch('actionsGetAllIncidencias', {'axios': this.$axios});
    this.items = await this.$store.getters.getAllIncidencias;
    this.$store.state.ok = false;
  },

  methods: {
    getUrl(foto){
      return this.url_aws+'/Incidencias/'+foto
    },
    openModal(foto) {
      this.showModal = true;
      this.selectedFoto = foto;
      console.log("aca estamos");
    },
    closeModal() {
      this.showModal = false;
    },
    dividirString(fotos) {
      return fotos.split(' | ');
    },
    exportarPDF() {
      fetch(logo)
          .then(response => response.blob())
          .then(blob => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () => {
              const base64logo = reader.result;
              var docDefinition = {
                content: [
                  {
                    image: base64logo,
                    width: 150,
                    alignment: 'center'
                  },
                  { text: '', alignment: 'center'},
                  { text: 'Fecha del Reporte: ' + this.fechaActual, alignment: 'right'},
                  { text: 'Incidencias', style: 'header' },
                  {
                    style: 'tableExample',
                    table: {
                      body: [
                          ['ID', 'Estado', 'Fecha', 'Descripción', 'Usuario'],
                        ...this.items.map((item) => [
                            item.id || 'No Tiene',
                          item.status === 'APROBADA' ? 'Aprobada' : 'Pendiente' || 'No Tiene',
                          this.dateHumanization(item.date) || 'No Tiene',
                          item.descripcion || 'No Tiene',
                          item.user.username || 'No Tiene',
                        ]),
                      ]
                    }
                  },
                ],
                styles: {
                  header: {
                    fontSize: 18,
                    bold: true,
                    margin: [0, 0, 0, 10]
                  },
                  tableExample: {
                    margin: [0, 5, 0, 15]
                  }
                }
              }

              this.$pdfMake.createPdf(docDefinition).download('Incidencias.pdf');
            };
          });
    },
    // async exportarPDF() {
    //   // await Promise.all(this.items.flatMap((item) => item.fotos.split('|').map((foto, fotoIndex) => new Promise((resolve, reject) => {
    //   //   const imgElement = document.querySelector(`img[data-item-id="${item.id}"][data-foto-index="${fotoIndex}"]`);
    //   //   imgElement.crossOrigin = "anonymous"; // Solicita el uso de CORS
    //   //   if (imgElement.complete) {
    //   //     resolve();
    //   //   } else {
    //   //     imgElement.onload = resolve;
    //   //     imgElement.onerror = reject;
    //   //   }
    //   // }))));
    //   // const imagenesBase64 = await Promise.all(this.items.flatMap((item) => {
    //   //   const fotos = item.fotos.split('|');
    //   //   return Promise.all(fotos.map((foto, fotoIndex) => {
    //   //     const imgElement = document.getElementById(`${item.id}-${fotoIndex}`);
    //   //     console.log("lasURL: ", imgElement.src);
    //   //
    //   //     return new Promise((resolve) => {
    //   //       const canvas = document.createElement('canvas');
    //   //       const context = canvas.getContext('2d');
    //   //       canvas.width = imgElement.naturalWidth;
    //   //       canvas.height = imgElement.naturalHeight;
    //   //       context.drawImage(imgElement, 0, 0);
    //   //       resolve(canvas.toDataURL());
    //   //     });
    //   //   }));
    //   // }));
    //   // var docDefinition = {
    //   //   content: [
    //   //     // ['ID', 'Estado', 'Fecha', 'Descripción', 'Usuario', 'Fotos'],
    //   //     ['ID', 'Estado', 'Fecha', 'Descripción', 'Usuario'],
    //   //     // ...this.items.map((item, index) => [
    //   //       ...this.items.map((item) => [
    //   //       item.id || 'No Tiene',
    //   //       item.status === 'APROBADA' ? 'Aprobada' : 'Pendiente' || 'No Tiene',
    //   //       this.dateHumanization(item.date) || 'No Tiene',
    //   //       item.descripcion || 'No Tiene',
    //   //       item.user.username || 'No Tiene',
    //   //       // ...imagenesBase64[index].map(imagenBase64 => ({ image: imagenBase64, width: 150 })) // Agrega esta línea
    //   //     ]),
    //   //   ],
    //   //   styles: {
    //   //     header: {
    //   //       fontSize: 18,
    //   //       bold: true,
    //   //       margin: [0, 0, 0, 10]
    //   //     },
    //   //     tableExample: {
    //   //       margin: [0, 5, 0, 15]
    //   //     }
    //   //   }
    //   // };
    //   var docDefinition = {
    //     content: [
    //       {
    //         image: base64logo,
    //         width: 150,
    //         alignment: 'center'
    //       },
    //       { text: '', alignment: 'center'},
    //       { text: 'Fecha del Reporte: ' + this.fechaActual, alignment: 'right'},
    //       { text: 'Incidencias', style: 'header' },
    //       {
    //         style: 'tableExample',
    //         table: {
    //           body: [
    //             ['ID', 'Estado', 'Fecha', 'Descripción', 'Usuario'],
    //             ...this.items.map((item) => [
    //               item.id || 'No Tiene',
    //               item.status === 'APROBADA' ? 'Aprobada' : 'Pendiente' || 'No Tiene',
    //               this.dateHumanization(item.date) || 'No Tiene',
    //               item.descripcion || 'No Tiene',
    //               item.user.username || 'No Tiene',
    //             ]),
    //           ]
    //         }
    //       },
    //     ],
    //     styles: {
    //       header: {
    //         fontSize: 18,
    //         bold: true,
    //         margin: [0, 0, 0, 10]
    //       },
    //       tableExample: {
    //         margin: [0, 5, 0, 15]
    //       }
    //     }
    //   }
    //   this.$pdfMake.createPdf(docDefinition).download('Incidencias.pdf');
    // },
    dateHumanization(date) {
      return Utils.dateHumanization(date);
    },
  },
};
</script>
<style scoped>
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loading-spinner {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s cubic-bezier(0.68, -0.55, 0.27, 1.55) infinite; /* Safari */
  animation: spin 2s cubic-bezier(0.68, -0.55, 0.27, 1.55) infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.action-link {
  margin-right: 10px; /* Ajusta este valor según tus necesidades */
}
.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.4);
}

.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}

.close-button {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close-button:hover,
.close-button:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}
</style>
