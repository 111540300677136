import { createStore } from "vuex";
import Auth from "@/models/Auth";
import User from "@/models/User";
import bootstrap from "bootstrap/dist/js/bootstrap.min.js";
import Order from "@/models/Orders";
import Roles from "@/models/Roles";
import Permission from "@/models/Permission";
import Area from "@/models/Area";
import Incidencias from "@/models/Incidencias";
import Utils from "@/utils/Utils";
import Impresiones from "@/models/Impresiones";
import PrintingProfile from "@/models/PrintingProfile";

export default createStore({
  state: {
    ok: false,
    userId: localStorage.getItem("userId"),
    hideConfigButton: false,
    isPinned: true,
    showConfig: false,
    sidebarType: "bg-gradient-dark",
    isRTL: false,
    color: "success",
    isNavFixed: false,
    isAbsolute: false,
    showNavs: true,
    showSidenav: true,
    showNavbar: true,
    showFooter: true,
    showMain: true,
    isDarkMode: false,
    navbarFixed:
      "position-sticky blur shadow-blur left-auto top-1 z-index-sticky px-0 mx-4",
    absolute: "position-absolute px-4 mx-0 w-100 z-index-2",
    bootstrap,
    token: localStorage.getItem("token"),
    errorMessage: "",
    newIdUser: 0,
    countItems: {
      ordenes: 0,
      reportes: 0,
      incidencias: 0,
      etiquetas: 0,
    },
    incidenciasWeeklyTotal: {},
    etiquetasWeeklyTotal: {},
    scanWeeklyTotal: {},
    impressionWeeklyTotal: {},
    incidenciasMontlyTotal: {},
    etiquetasMontlyTotal: {},
    scanMontlyTotal: {},
    impressionMontlyTotal: {},
    etiquetasAnnualTotal: {},
    impresionesAnnualTotal: {},
    scanAnnualTotal: {},
    incidenciasAnnualTotal: {},
    etiquetasDaily: 0,
    impresionesDaily: 0,
    scanDaily: 0,
    incidenciasDaily: 0,
    currentUser: localStorage.getItem("currentUser"),
    user: {},
    role: {},
    area: {},
    permission: {},
    printingProfile: {},
    incidencias: {},
    order: {},
    orderList: [],
    usersList: [],
    rolesList: [],
    areasList: [],
    printingProfileList: [],
    incidenciasList: [],
    impresionesList: [],
  },
  mutations: {
    toggleConfigurator(state) {
      state.showConfig = !state.showConfig;
    },
    toggleEveryDisplay(state) {
      state.showNavbar = !state.showNavbar;
      state.showSidenav = !state.showSidenav;
      state.showFooter = !state.showFooter;
    },
    toggleHideConfig(state) {
      state.hideConfigButton = !state.hideConfigButton;
    },
    setIsPinned(state, payload) {
      state.isPinned = payload;
    },
    color(state, payload) {
      state.color = payload;
    },
    navbarMinimize(state) {
      const sidenavShow = document.getElementsByClassName("g-sidenav-show")[0];

      if (sidenavShow.classList.contains("g-sidenav-pinned")) {
        sidenavShow.classList.remove("g-sidenav-pinned");
        sidenavShow.classList.add("g-sidenav-hidden");
        state.isPinned = true;
      } else {
        sidenavShow.classList.remove("g-sidenav-hidden");
        sidenavShow.classList.add("g-sidenav-pinned");
        state.isPinned = false;
      }
    },
    navbarFixed(state) {
      if (state.isNavFixed === false) {
        state.isNavFixed = true;
      } else {
        state.isNavFixed = false;
      }
    },
    SET_ERROR_MESSAGE(state, payload) {
      state.errorMessage = payload;
    },
    SET_NEW_ID_USER(state, payload) {
      state.newIdUser = payload;
    },
    // LOGIN
    async LOGIN(state, payload) {
      state.tokenAuth = await Auth.login(
          payload.axios,
          payload.username,
          payload.password,
          payload.store
      );
      // console.log('El tokenAuth Tiene Esto...!!!', state.tokenAuth);
      state.token = state.tokenAuth.token;
      if (state.token !== null) {
        localStorage.setItem("token", state.token);
        localStorage.setItem("userId", state.tokenAuth.user.pk);
        localStorage.setItem("refreshToken", state.tokenAuth.refreshToken);
        state.errorMessage = "";
      } else {
        if (
            localStorage.getItem("token") === null ||
            localStorage.getItem("token") === "null"
        ) {
          localStorage.setItem("token", "null-new");
          state.token = "null-new";
        } else {
          localStorage.setItem("token", null);
          state.token = null;
        }
      }
    },
    async LOGOUT(state, payload) {
      state.token = null;
      state.currentUser = null;
      state.errorMessage = "";
      localStorage.removeItem("token");
      localStorage.removeItem("userId");
      localStorage.removeItem("currentUser");
      localStorage.removeItem("refreshToken");
      await Auth.logout(payload.axios, payload.refreshToken, payload.store);
    },
    async ME(state, payload) {
      state.currentUser = await Auth.me(payload.axios);
      state.userId = Number(state.currentUser.id);
      localStorage.setItem("userId", state.userId);
      localStorage.setItem("currentUser", JSON.stringify(state.currentUser));
    },
    async USERS_LIST(state, payload){
      state.usersList = await User.all(
          payload.axios
      )
    },
    async USER(state, payload) {
      state.user = await User.retrieve(payload.axios, payload.id);
    },
    async CREATE_USER(state, payload) {
      state.ok = await User.create(
          payload.axios,
          payload.password,
          payload.username,
          payload.email,
          payload.area,
          payload.phone,
          payload.firstName,
          payload.lastName,
          payload.role,
      );
      console.log("state.ok: ", state.ok);
    },
    async EDIT_USER(state, payload) {
      state.ok = await User.edit(
          payload.axios,
          payload.id,
          payload.username,
          payload.email,
          payload.area,
          payload.phone,
          payload.firstName,
          payload.lastName,
          payload.role,
          payload.password,
      );
      console.log("state.ok: ", state.ok);
    },
    async CURRENT_USER(state, payload) {
      let currentUser = state.currentUser;
      if (state.userId > 0) {
        currentUser = await User.retrieve(
            payload.axios,
            localStorage.getItem("userId")
        );
        localStorage.setItem("currentUser", JSON.stringify(currentUser));
      }
    },
    async DELETE_USER(state, payload) {
      if (payload.id > 0) {
        state.ok = await User.delete(payload.axios, payload.id);
      } else {
        state.ok = null;
      }
    },
    async GET_ALL_ORDERS(state, payload){
      state.orderList = await Order.all(payload.axios, payload.page, payload.pageSize);
    },
    async GET_ALL_AREAS(state, payload){
      state.areasList = await Area.all(payload.axios);
    },
    async GET_AREA(state, payload) {
      state.area = await Area.retrieve(payload.axios, payload.id);
    },
    async CREATE_AREA(state, payload) {
      state.ok = await Area.create(
          payload.axios,
          payload.name,
      );
      console.log("state.ok: ", state.ok);
    },
    async EDIT_AREA(state, payload) {
      state.ok = await Area.edit(
          payload.axios,
          payload.id,
          payload.name,
      );
      console.log("state.ok: ", state.ok);
    },
    async DELETE_AREA(state, payload) {
      if (payload.id > 0) {
        state.ok = await Area.delete(payload.axios, payload.id);
      } else {
        state.ok = null;
      }
    },
    async GET_ALL_ROLES(state, payload){
      state.rolesList = await Roles.all(payload.axios);
    },
    async CREATE_ROLE(state, payload) {
      state.ok = await Roles.create(
          payload.axios,
          payload.name,
          payload.role,
      );
      console.log("state.ok: ", state.ok);
    },
    async GET_ROLE(state, payload) {
      state.role = await Roles.retrieve(payload.axios, payload.id);
    },
    async EDIT_ROLE(state, payload) {
      state.ok = await Roles.edit(
          payload.axios,
          payload.id,
          payload.name,
      );
      console.log("state.ok: ", state.ok);
    },
    async DELETE_ROLE(state, payload) {
      if (payload.id > 0) {
        state.ok = await Roles.delete(payload.axios, payload.id);
      } else {
        state.ok = null;
      }
    },
    async GET_ALL_IMPRESSIONS(state, payload){
      state.impresionesList = await Impresiones.all(payload.axios);
    },
    async GET_ALL_INCIDENCIAS(state, payload){
      state.incidenciasList = await Incidencias.all(payload.axios);
    },
    async GET_INCIDENCIA(state, payload) {
      state.incidencia = await Incidencias.retrieve(payload.axios, payload.id);
    },
    async GET_ORDER(state, payload) {
      state.order = await Order.retrieve(payload.axios, payload.id);
    },
    async EDIT_INCIDENCIA(state, payload) {
      state.ok = await Incidencias.edit(
          payload.axios,
          payload.id,
          payload.status,
      );
      console.log("state.ok: ", state.ok);
    },
    async GET_ALL_PRINTING_PROFILE(state, payload){
      state.printingProfileList = await PrintingProfile.all(payload.axios);
    },
    async GET_PRINTING_PROFILE(state, payload) {
      state.printingProfile = await PrintingProfile.retrieve(payload.axios, payload.id);
    },
    async EDIT_PRINTING_PROFILE(state, payload) {
      state.ok = await PrintingProfile.edit(
          payload.axios,
          payload.id,
          payload.name,
          payload.sheetSize,
          payload.labelSize,
          payload.leafMargins,
          payload.sheetPosition,
          payload.separationBetweenLabels,
          payload.user
      );
      console.log("state.ok: ", state.ok);
    },
    async CREATE_PRINTING_PROFILE(state, payload) {
      state.ok = await PrintingProfile.create(
          payload.axios,
          payload.name,
          payload.sheetSize,
          payload.labelSize,
          payload.leafMargins,
          payload.sheetPosition,
          payload.separationBetweenLabels,
          payload.user
      );
      console.log("state.ok: ", state.ok);
    },
    async DELETE_PRINTING_PROFILE(state, payload) {
      if (payload.id > 0) {
        state.ok = await PrintingProfile.delete(payload.axios, payload.id);
      } else {
        state.ok = null;
      }
    },
    async GET_COUNT_ITEMS(state, payload){
      state.countItems = await Utils.counItems(payload.axios);
      console.log("ResponseCountItems: ", state.countItems);
    },
    async GET_SCAN_WEEKLY_TOTAL(state, payload){
      state.scanWeeklyTotal = await Utils.scanWeeklyTotal(payload.axios);
    },
    async GET_ETQUETAS_WEEKLY_TOTAL(state, payload){
      state.etiquetasWeeklyTotal = await Utils.etiquetasWeeklyTotal(payload.axios);
    },
    async GET_INCIDENCIAS_WEEKLY_TOTAL(state, payload){
      state.incidenciasWeeklyTotal = await Utils.incidenciasWeeklyTotal(payload.axios);
    },
    async GET_IMPRESSION_WEEKLY_TOTAL(state, payload){
      state.impressionWeeklyTotal = await Utils.impressionWeeklyTotal(payload.axios);
    },
    async GET_SCAN_MONTLY_TOTAL(state, payload){
      state.scanMontlyTotal = await Utils.scanMonthlyTotal(payload.axios);
    },
    async GET_ETIQUETAS_MONTLY_TOTAL(state, payload){
      state.etiquetasMontlyTotal = await Utils.etiquetasMonthlyTotal(payload.axios);
    },
    async GET_INCIDENCIAS_MONTLY_TOTAL(state, payload){
      state.incidenciasMontlyTotal = await Utils.incidenciasMonthlyTotal(payload.axios);
    },
    async GET_IMPRESSION_MONTLY_TOTAL(state, payload){
      state.impressionMontlyTotal = await Utils.impressionMonthlyTotal(payload.axios);
    },
    async GET_ETIQUETAS_ANUAL_TOTAL(state, payload){
      state.etiquetasAnnualTotal = await Utils.etiquetasAnnualTotal(payload.axios);
    },
    async GET_SCAN_ANUAL_TOTAL(state, payload){
      state.scanAnnualTotal = await Utils.scanAnnualTotal(payload.axios);
    },
    async GET_IMPRESSION_ANUAL_TOTAL(state, payload){
      state.impresionesAnnualTotal = await Utils.impressionAnnualTotal(payload.axios);
    },
    async GET_INCIDENCIAS_ANUAL_TOTAL(state, payload){
      state.incidenciasAnnualTotal = await Utils.incidenciasAnnualTotal(payload.axios);
    },
    async GET_INCIDENCIAS_DAILY(state, payload){
      state.incidenciasDaily = await Utils.incidenciasDailyTotal(payload.axios);
    },
    async GET_SCAN_DAILY(state, payload){
      state.scanDaily = await Utils.scanDailyTotal(payload.axios);
    },
    async GET_IMPRESSIONS_DAILY(state, payload){
      state.impresionesDaily = await Utils.impressionDailyTotal(payload.axios);
    },
    async GET_ETIQUETAS_DAILY(state, payload){
      state.etiquetasDaily = await Utils.etiquetasDailyTotal(payload.axios);
    },
    async GET_PERMISSION(state, payload) {
      state.permission = await Permission.retrieve(payload.axios, payload.id);
    },
    async CREATE_PERMISSION(state, payload) {
      state.ok = await Permission.create(
          payload.axios,
          payload.name,
          payload.role,
      );
      console.log("state.ok: ", state.ok);
    },
    async EDIT_PERMISSION(state, payload) {
      state.ok = await Permission.edit(
          payload.axios,
          payload.id,
          payload.name,
          payload.role,
      );
      console.log("state.ok: ", state.ok);
    },

  },
  actions: {
    actionsUser({ commit }, axios) {
      commit("USER", axios);
    },
    setColor({ commit }, payload) {
      commit("color", payload);
    },
    actionsLogin({ commit }, payload) {
      commit("LOGIN", payload);
    },
    actionsLogout({ commit }, payload) {
      commit("LOGOUT", payload);
    },
    actionsMe({ commit }, payload) {
      commit("ME", payload);
    },
    actionsCurrentUser({ commit }, axios) {
      commit("CURRENT_USER", axios);
    },
    actionsCreateUser({ commit }, payload) {
      commit("CREATE_USER", payload);
    },
    actionsEditUser({ commit }, payload) {
      commit("EDIT_USER", payload);
    },
    actionsDeleteUser({ commit }, payload) {
      commit("DELETE_USER", payload);
    },
    actionsGetAllOrders({ commit }, axios) {
      commit("GET_ALL_ORDERS", axios);
    },
    actionsGetOrder({ commit }, axios) {
      commit("GET_ORDER", axios);
    },
    actionsGetAllImpressions({ commit }, axios) {
      commit("GET_ALL_IMPRESSIONS", axios);
    },
    actionsGetAllIncidencias({ commit }, axios) {
      commit("GET_ALL_INCIDENCIAS", axios);
    },
    actionsGetIncidencia({ commit }, axios) {
      commit("GET_INCIDENCIA", axios);
    },
    actionsEditIncidencia({ commit }, payload) {
      commit("EDIT_INCIDENCIA", payload);
    },
    actionsGetAllUsers({ commit }, axios) {
      commit("USERS_LIST", axios);
    },
    actionsGetAllAreas({ commit }, axios) {
      commit("GET_ALL_AREAS", axios);
    },
    actionsGetArea({ commit }, axios) {
      commit("GET_AREA", axios);
    },
    actionsCreateArea({ commit }, payload) {
      commit("CREATE_AREA", payload);
    },
    actionsEditArea({ commit }, payload) {
      commit("EDIT_AREA", payload);
    },
    actionsDeleteArea({ commit }, payload) {
      commit("DELETE_AREA", payload);
    },
    actionsGetAllRoles({ commit }, axios) {
      commit("GET_ALL_ROLES", axios);
    },
    actionsCreateRole({ commit }, payload) {
      commit("CREATE_ROLE", payload);
    },
    actionsGetRole({ commit }, axios) {
      commit("GET_ROLE", axios);
    },
    actionsEditRole({ commit }, payload) {
      commit("EDIT_ROLE", payload);
    },
    actionsDeleteRole({ commit }, payload) {
      commit("DELETE_ROLE", payload);
    },
    actionsGetPermission({ commit }, axios) {
      commit("GET_PERMISSION", axios);
    },
    actionsCreatePermission({ commit }, payload) {
      commit("CREATE_PERMISSION", payload);
    },
    actionsEditPermission({ commit }, payload) {
      commit("EDIT_PERMISSION", payload);
    },
    actionsGetAllPrintingProfile({ commit }, axios) {
      commit("GET_ALL_PRINTING_PROFILE", axios);
    },
    actionsGetPrintingProfile({ commit }, axios) {
      commit("GET_PRINTING_PROFILE", axios);
    },
    actionsEditPrintingProfile({ commit }, payload) {
      commit("EDIT_PRINTING_PROFILE", payload);
    },
    actionsCreatePrintingProfile({ commit }, payload) {
      commit("CREATE_PRINTING_PROFILE", payload);
    },
    actionsDeletePrintingProfile({ commit }, payload) {
      commit("DELETE_PRINTING_PROFILE", payload);
    },
    actionsGetCountItems({ commit }, axios) {
      commit("GET_COUNT_ITEMS", axios);
    },
    actionsScanWeeklyTotal({ commit }, axios) {
      commit("GET_SCAN_WEEKLY_TOTAL", axios);
    },
    actionsIncidenciasWeeklyTotal({ commit }, axios) {
      commit("GET_INCIDENCIAS_WEEKLY_TOTAL", axios);
    },
    actionsEtiquetasWeeklyTotal({ commit }, axios) {
      commit("GET_ETQUETAS_WEEKLY_TOTAL", axios);
    },
    actionsImpressionWeeklyTotal({ commit }, axios) {
      commit("GET_IMPRESSION_WEEKLY_TOTAL", axios);
    },
    actionsScanMontlyTotal({ commit }, axios) {
      commit("GET_SCAN_MONTLY_TOTAL", axios);
    },
    actionsIncidenciasMontlyTotal({ commit }, axios) {
      commit("GET_INCIDENCIAS_MONTLY_TOTAL", axios);
    },
    actionsEtiquetasMontlyTotal({ commit }, axios) {
      commit("GET_ETIQUETAS_MONTLY_TOTAL", axios);
    },
    actionsImpressionMontlyTotal({ commit }, axios) {
      commit("GET_IMPRESSION_MONTLY_TOTAL", axios);
    },
    actionsEtiquetasAnnualTotal({ commit }, axios) {
      commit("GET_ETIQUETAS_ANUAL_TOTAL", axios);
    },
    actionsScanAnnualTotal({ commit }, axios) {
      commit("GET_SCAN_ANUAL_TOTAL", axios);
    },
    actionsImpressionAnnualTotal({ commit }, axios) {
      commit("GET_IMPRESSION_ANUAL_TOTAL", axios);
    },
    actionsIncidenciasAnnualTotal({ commit }, axios) {
      commit("GET_INCIDENCIAS_ANUAL_TOTAL", axios);
    },
    actionsIncidenciasDaily({ commit }, axios) {
      commit("GET_INCIDENCIAS_DAILY", axios);
    },
    actionsScanDaily({ commit }, axios) {
      commit("GET_SCAN_DAILY", axios);
    },
    actionsImpressionsDaily({ commit }, axios) {
      commit("GET_IMPRESSIONS_DAILY", axios);
    },
    actionsEtiquetasDaily({ commit }, axios) {
      commit("GET_ETIQUETAS_DAILY", axios);
    },
    actionsSetErrorMessage({ commit }, payload) {
      commit("SET_ERROR_MESSAGE", payload);
    },
    actionsSetNewIdUser({ commit }, payload) {
      commit("SET_NEW_ID_USER", payload);
    },
  },
  getters: {
    getErrorMessage: (state) => {
      return state.errorMessage;
    },
    // AUTH
    getToken: (state) => {
      return state.token;
    },
    getMe: (state) => {
      return state.userId;
    },
    getRefreshToken: () =>{
      return localStorage.getItem('refreshToken')
    },
    // END AUTH
    getCurrentUserAuth: (state) => {
      return state.currentUser;
    },
    getUser: (state) => {
      return state.user;
    },
    getAllOrders: (state) => {
      return state.orderList;
    },
    getCountItems: (state) => {
      return state.countItems;
    },
    getScanWeeklyTotal: (state) => {
      return state.scanWeeklyTotal;
    },
    getIncidenciasWeeklyTotal: (state) => {
      return state.incidenciasWeeklyTotal;
    },
    getEtiquetasWeeklyTotal: (state) => {
      return state.etiquetasWeeklyTotal;
    },
    getImpressionWeeklyTotal: (state) => {
      return state.impressionWeeklyTotal;
    },
    getScanMontlyTotal: (state) => {
      return state.scanMontlyTotal;
    },
    getIncidenciasMontlyTotal: (state) => {
      return state.incidenciasMontlyTotal;
    },
    getEtiquetasMontlyTotal: (state) => {
      return state.etiquetasMontlyTotal;
    },
    getImpressionMontlyTotal: (state) => {
      return state.impressionMontlyTotal;
    },
    getEtiquetasAnualTotal: (state) => {
      return state.etiquetasAnnualTotal;
    },
    getScanAnualTotal: (state) => {
      return state.scanAnnualTotal;
    },
    getImpressionAnualTotal: (state) => {
      return state.impresionesAnnualTotal;
    },
    getIncidenciasAnualTotal: (state) => {
      return state.incidenciasAnnualTotal;
    },
    getIncidenciasDaily: (state) => {
      return state.incidenciasDaily;
    },
    getImpressionDaily: (state) => {
      return state.impresionesDaily;
    },
    getEtiquetasDaily: (state) => {
      return state.etiquetasDaily;
    },
    getScanDaily: (state) => {
      return state.scanDaily;
    },
    getAllImpresiones: (state) => {
      return state.impresionesList;
    },
    getAllIncidencias: (state) => {
      return state.incidenciasList;
    },
    getOrder: (state) => {
      return state.order;
    },
    getAllUsers: (state) => {
      return state.usersList;
    },
    getAllAreas: (state) => {
      return state.areasList;
    },
    getAllRoles: (state) => {
      return state.rolesList;
    },
    getRole: (state) => {
      return state.role;
    },
    getAllPermissions: (state) => {
      return state.permissionList;
    },
    getPermission: (state) => {
      return state.permission;
    },
  }
});
