import {createRouter, createWebHashHistory} from "vue-router";
import Default from "../views/dashboards/Default.vue";
import Sales from "../views/dashboards/Sales.vue";
import Overview from "../views/pages/profile/Overview.vue";
import Projects from "../views/pages/profile/Projects.vue";
import Timeline from "../views/pages/projects/Timeline.vue";
import Pricing from "../views/pages/Pricing.vue";
import RTL from "../views/pages/Rtl.vue";
import Charts from "../views/pages/Charts.vue";
import Notifications from "../views/pages/Notifications.vue";
import Kanban from "../views/applications/Kanban.vue";
import Wizard from "../views/applications/wizard/Wizard.vue";
import DataTables from "../views/applications/DataTables.vue";
import Calendar from "../views/applications/Calendar.vue";
import NewProduct from "../views/ecommerce/products/NewProduct.vue";
import EditProduct from "../views/ecommerce/products/EditProduct.vue";
import ProductPage from "../views/ecommerce/products/ProductPage.vue";
import OrderDetails from "../views/ecommerce/orders/OrderDetails";
import OrderList from "../views/ecommerce/orders/OrderList";
import NewUser from "../views/pages/users/NewUser.vue";
import Widgets from "../views/pages/Widgets.vue";
import Basic from "../views/auth/signin/Basic.vue";
import Cover from "../views/auth/signin/Cover.vue";
import Illustration from "../views/auth/signin/Illustration.vue";
import ResetCover from "../views/auth/reset/Cover.vue";
import SignupCover from "../views/auth/signup/Cover.vue";
import store from "@/store";
import UsersList from "@/views/pages/users/UsersList.vue";
import EditUser from "@/views/pages/users/EditUser.vue";
import RolesList from "@/views/pages/roles/RolesList.vue";
import NewRole from "@/views/pages/roles/NewRole.vue";
import EditRole from "@/views/pages/roles/EditRole.vue";
import DeleteRol from "@/views/pages/roles/DeleteRole.vue";
import DeleteUser from "@/views/pages/users/DeleteUser.vue";
import AreasList from "@/views/pages/areas/AreasList.vue";
import NewArea from "@/views/pages/areas/NewArea.vue";
import EditArea from "@/views/pages/areas/EditArea.vue";
import IncidenciasList from "@/views/pages/incidencias/IncidenciasList.vue";
import EditIncidencia from "@/views/pages/incidencias/EditIncidencia.vue";
import ImpresionesList from "@/views/pages/impresiones/ImpresionesList.vue";
import NewPrintingProfile from "@/views/pages/printingProfile/NewPrintingProfile.vue";
import EditPrintingProfile from "@/views/pages/printingProfile/EditPrintingProfile.vue";
import PrintingProfileList from "@/views/pages/printingProfile/PrintingProfileList.vue";
import DeletePrintingProfile from "@/views/pages/printingProfile/DeletePrintingProfile.vue";
import ViewIncidencia from "@/views/pages/incidencias/ViewIncidencia.vue";
import DeleteArea from "@/views/pages/areas/DeleteArea.vue";
import Logout from "@/views/auth/signin/Logout.vue";

const routes = [
  {
    path: "/",
    name: "Default Route",
    redirect: "/login",
    meta: {
      requiresAuth: false
    }
  },
  {
    path: "/login",
    name: "Signin Basic",
    component: Basic,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: "/authentication/signin/cover",
    name: "Signin Cover",
    component: Cover,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: "/dashboards/dashboard-default",
    name: "Dashboards",
    component: Default,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/dashboards/sales",
    name: "Sales",
    component: Sales,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/profile/overview",
    name: "Informacion General del Perfil",
    component: Overview,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/profile/projects",
    name: "All Projects",
    component: Projects,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/projects/timeline",
    name: "Timeline",
    component: Timeline,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/pricing-page",
    name: "Pricing Page",
    component: Pricing,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/rtl-page",
    name: "RTL",
    component: RTL,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/charts",
    name: "Charts",
    component: Charts,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/widgets",
    name: "Widgets",
    component: Widgets,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/notifications",
    name: "Notifications",
    component: Notifications,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/applications/kanban",
    name: "Kanban",
    component: Kanban,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/applications/wizard",
    name: "Wizard",
    component: Wizard,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/applications/data-tables",
    name: "Data Tables",
    component: DataTables,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/applications/calendar",
    name: "Calendar",
    component: Calendar,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/ecommerce/products/new-product",
    name: "New Product",
    component: NewProduct,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/ecommerce/products/edit-product",
    name: "Edit Product",
    component: EditProduct,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/products/product-page",
    name: "Product Page",
    component: ProductPage,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/orders/order-details/:id",
    name: "Detalles de Orden",
    component: OrderDetails,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/orders/order-list",
    name: "Listado de Órdenes",
    component: OrderList,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/users/new-user",
    name: "Nuevo Usuario",
    component: NewUser,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/users/edit-user/:id",
    name: "Editar Usuario",
    component: EditUser,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/users/user-list",
    name: "Lista de Usuario",
    component: UsersList,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/users/user-delete/:id",
    name: "Eliminar Usuario",
    component: DeleteUser,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/logout",
    name: "Cerrar Sesión",
    component: Logout,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/report/incidencias/view/:id",
    name: "Ver Incidencia",
    component: ViewIncidencia,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/report/incidencias/edit/:id",
    name: "Editar Incidencia",
    component: EditIncidencia,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/report/incidencias/list",
    name: "Lista de Incidencias",
    component: IncidenciasList,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/report/impresiones/list",
    name: "Lista de Impresiones",
    component: ImpresionesList,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/area/new",
    name: "Impresiones",
    component: NewArea,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/area/area-list",
    name: "Listado de Áreas",
    component: AreasList,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/area/new",
    name: "Nueva Área",
    component: NewArea,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/area/edit-area/:id",
    name: "Editar Área",
    component: EditArea,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/area/delete-area/:id",
    name: "Eliminar Área",
    component: DeleteArea,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/roles/roles-list",
    name: "Listado de Roles",
    component: RolesList,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/roles/new",
    name: "Nuevo Rol",
    component: NewRole,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/users/delete-rol/:id",
    name: "Eliminar Rol",
    component: DeleteRol,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/users/edit-rol/:id",
    name: "Editar Rol",
    component: EditRole,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/orders/order-details",
    name: "Detalles de Rol",
    component: OrderDetails,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/printingProfile/new",
    name: "Nuevo Perfil de Impresión",
    component: NewPrintingProfile,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/printingProfile/delete/:id",
    name: "Eliminar Perfil de Impresión",
    component: DeletePrintingProfile,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/printingProfile/edit/:id",
    name: "Editar Perfil de Impresión",
    component: EditPrintingProfile,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/printingProfile/list",
    name: "Listado de Perfil de Impresión",
    component: PrintingProfileList,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/roles/roles-new",
    name: "Nuevo Rol",
    component: NewRole,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/users/edit-rol/:id",
    name: "Editar Rol",
    component: EditRole,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/orders/order-details",
    name: "Detalles de Rol",
    component: OrderDetails,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/authentication/signin/illustration",
    name: "Signin Illustration",
    component: Illustration,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: "/authentication/reset/cover",
    name: "Reset Cover",
    component: ResetCover,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/authentication/signup/cover",
    name: "Signup Cover",
    component: SignupCover,
    meta: {
      requiresAuth: false
    }
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth) && store.state.token === null) {
    next('/login')
  } else {
    next()
  }
})

export default router;
