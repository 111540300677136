<template>
  <span :class="['chip', color, { dark: dark }]">{{ text }}</span>
</template>

<script>
export default {
  props: {
    text: String,
    color: String,
    dark: Boolean,
  },
}
</script>

<style scoped>
.chip {
  display: inline-block;
  padding: 0.5em 1em;
  border-radius: 1em;
  font-size: 0.875em;
  color: white;
}

.chip.green {
  background-color: #4caf50;
}

.chip.red {
  background-color: #f44336;
}

.chip.blue {
  background-color: #0088ff;
}

.chip.dark {
  color: black;
}
</style>
