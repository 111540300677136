<template>
  <div v-if="isLoading" class="loading-overlay">
    <div class="loading-spinner"></div>
  </div>
  <div class="container-fluid py-4">
    <div class="d-sm-flex justify-content-between">
      <div>
<!--        <material-button color="success" variant="gradient">New order</material-button>-->
      </div>
<!--      <div class="d-flex">-->
<!--                <material-button-->
<!--                    class="btn-icon ms-2"-->
<!--                    size=""-->
<!--                    color="success"-->
<!--                    variant="gradient"-->
<!--                    data-type="csv"-->
<!--                    @click="onButtonClick"-->
<!--                >-->
<!--                  <span class="btn-inner&#45;&#45;text">Importar CSV</span>-->
<!--                </material-button>-->
<!--                <input-->
<!--                    ref="fileInput"-->
<!--                    type="file"-->
<!--                    style="display: none"-->
<!--                    @change="onFileChange"-->
<!--                >-->
<!--      </div>-->
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card mt-4">
          <div class="card-header">
            <h5 class="mb-0">Listado de Roles</h5>
          </div>
          <div class="table-responsive">
            <div v-if="error !== ''">
              <label
                  type="error"
                 :value="alert"
                  class="alert-warning text-bold"
              >
                {{ error }}
              </label>
            </div>
            <div v-if="message !== ''" class="bg-gradient-light">
              <label
                  type="success"
                  :value="show_text"
              >
                {{ message }}
              </label>
            </div>
            <div class="table-responsive">
              <table id="roles-list" class="table table-flush">
                <thead class="thead-light">
                <tr>
                  <th>Id</th>
                  <th>Nombre</th>
                  <th>Acción</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="item in items" :key="item.id">
                  <td>{{ item.id }}</td>
                  <td>{{ item.name }}</td>
                  <td>
                    <router-link class="action-link" :to="`/users/edit-rol/${item.id}`">
                      <i class="fas fa-pencil-alt action color-accent" />
                    </router-link>
                    <router-link class="action-link" :to="`/users/delete-rol/${item.id}`">
                      <i class="fas fa-trash action color-accent" />
                    </router-link>
                  </td>

                </tr>
                </tbody>
              </table>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import DataTable from 'datatables.net-vue3';
import { DataTable } from "simple-datatables";
// import MaterialButton from "@/components/MaterialButton.vue";
import Utils from "@/utils/Utils";
// import ChipCard from "@/views/ecommerce/components/ChipCard.vue";


export default {
  name: "RolesList",
  components: {
    // ChipCard,
    // MaterialButton,
    // DataTable
  },
  data: () => ({
    isLoading: false,
    datatable: null,
    itemsUpload: [],
    showTable: true,
    items: [],
    textConfirmDelete: "¿Está seguro que desea eliminar el Usuario?",
    error: '',
    message: '',
    show_text: false,
    alert: false,
  }),
  watch: {
    '$store.state.rolesList': function (roles) {
      this.items = roles;
      console.log("items: ", this.items);
      if (this.datatable) {
        this.datatable.destroy();
      }
      this.$nextTick(() => {
        this.datatable = new DataTable("#roles-list", {
          searchable: true,
          fixedHeight: false,
          perPage: 15,
          perPageSelect: [15, 20, 25, 'Todos'],
          labels: {
            placeholder: "Buscar...", // Placeholder del campo de búsqueda
            perPage: "{select} registros por página", // Entradas por página
            noRows: "No hay registros", // Mensaje cuando no hay entradas
            noResults: "No se encontraron registros",// Mensaje cuando no hay entradas
            info: "Mostrando {start} a {end} de {rows} entradas", // Información de las entradas mostradas
          }
        });

        document.querySelector('.dataTable-dropdown').addEventListener('change', (event) => {
          if (event.target.value === "Todos") {
            console.log("mostrando todos:", roles.length);
            this.datatable.options.perPage = roles.length;
            this.datatable.update();
          }
        });
        let inputElement = document.querySelector(".dataTable-search input");
        let iconElement = document.createElement("i");
        iconElement.className = "fas fa-search";
        inputElement.parentNode.appendChild(iconElement);
        inputElement.placeholder = "Buscar...";
        inputElement.parentNode.style.position = "relative";
        iconElement.style.position = "absolute";
        iconElement.style.top = "50%";
        iconElement.style.right = "10px";
        iconElement.style.transform = "translateY(-50%)";

        let dropdownElement = document.querySelector(".dataTable-dropdown");
        let infoElement = document.querySelector(".dataTable-info");
        let paginationElement = document.querySelector(".dataTable-pagination");
        let bottomElement = document.querySelector(".dataTable-bottom");
        dropdownElement.parentNode.removeChild(dropdownElement);
        infoElement.parentNode.removeChild(infoElement);
        paginationElement.parentNode.removeChild(paginationElement);
        bottomElement.appendChild(infoElement);
        bottomElement.appendChild(dropdownElement);

        bottomElement.appendChild(paginationElement);
        bottomElement.style.display = "flex";
        bottomElement.style.justifyContent = "space-between";
      }).then(() => {
        this.isLoading = false;
      });
    },
  },

  methods: {
    deleteItemWrapper(itemId, event) {
      event.stopPropagation();
      this.deleteItem(itemId);
    },
    deleteItem(id) {
      alert(this.textConfirmDelete);
      console.log("eliminando usuario-id: ", id)
      // const index = this.items.indexOf(item);
      // if (confirm(this.textConfirmDelete)) {
      //   this.deleteUser(item.id);
      //   this.items.splice(index, 1);
      // }
    },
    deleteUser(id) {
      console.log("eliminando usuario-id: ", id)

      // this.$store.dispatch("actionsDeleteUser", {
      //   axios: this.$axios,
      //   id: id,
      // });
    },
    dateHumanization(date) {
      return Utils.dateHumanization(date);
    },
    onButtonClick() {
      this.$refs.fileInput.click();
    },
    onFileChange(e) {
      let file = e.target.files[0];
      let formData = new FormData();
      formData.append('file', file);
      this.uploadFile(formData);
      this.isLoading = true
    },
    uploadFile(formData) {
      this.$axios.post('/upload_csv/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(async response => {
        console.log(response.data);
        this.show_text = true;
        this.message = response.data[0]['message']
        this.isLoading = false
      }).catch(error => {
        console.error(error);
        this.alert = true;
        this.error = error
        this.isLoading = false
      });
    },
  },
  async mounted() {
    this.isLoading = true;
    this.$store.dispatch('actionsGetAllRoles', {'axios': this.$axios});
    this.items = await this.$store.getters.getAllRoles;
    this.$store.state.ok = false;
    },
};
</script>
<style scoped>
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loading-spinner {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s cubic-bezier(0.68, -0.55, 0.27, 1.55) infinite; /* Safari */
  animation: spin 2s cubic-bezier(0.68, -0.55, 0.27, 1.55) infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.action-link {
  margin-right: 10px; /* Ajusta este valor según tus necesidades */
}
</style>
