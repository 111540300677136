<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-12">
        <div class="multisteps-form mb-9">
          <div class="row">
            <div class="col-12 col-lg-8 mx-auto my-5"></div>
          </div>
          <div class="row">
            <div class="col-12 col-lg-8 m-auto">
              <div class="card">
                <div
                    class="card-header p-0 position-relative mt-n4 mx-3 z-index-2"
                >
                  <div
                      class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
                  >
                    <div class="multisteps-form__progress">
                      <button
                          class="multisteps-form__progress-btn"
                          type="button"
                          title="Crear Área"
                          :class="activeStep >= 0 ? activeClass : ''"
                      >
                        <span>Crear Área</span>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="card-body">
                  <form class="multisteps-form__form">
                    <!--single form panel-->
                    <h5 class="font-weight-bolder mb-0">Información de Área</h5>
                    <div class="multisteps-form__content">
                      <div class="row mt-3">
                        <div class="col-12 col-sm-6">
                          <label class="form-label col-sm-12">
                            <input
                                id="name"
                                v-model="item.name"
                                placeholder=" "
                                type="text"
                                class="form-control border-bottom"
                                name="name"
                                required
                                :minlength="minLen"
                                :maxlength="maxLen"
                                @input="checkLength('name', maxLen); checkMinLength('name', minLen)"
                            />
                            <span>Nombre Área *</span>
                          </label>
                        </div>
                      </div>
                      <div v-if="error !== ''">
                        <label
                            type="error"
                            :value="alert"
                            class="alert-warning text-bold"
                        >
                          {{ error }}
                        </label>
                      </div>
                      <div class="button-row d-flex mt-4">
                        <material-button
                            type="button"
                            color="danger"
                            variant="danger"
                            class="js-btn-send"
                            @click="getRouteAreaList()"
                        >Cancelar</material-button
                        >
                        <material-button
                            type="button"
                            color="success"
                            variant="gradient"
                            class="ms-auto js-btn-send"
                            :disabled="!isFormValid || error !== ''"
                            @click="createArea()"
                        >Aceptar</material-button
                        >
                      </div>
                    </div>

                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MaterialButton from "@/components/MaterialButton.vue";


export default {
  name: "NewArea",
  components: {
    MaterialButton,
  },
  data() {
    return {
      maxLen: 30,
      minLen: 2,
      showMenu: false,
      activeClass: "js-active position-relative",
      activeStep: 0,
      formSteps: 3,
      error: '',
      ok: null,
      alert: false,
      item: {
        name: '',
      }
    };
  },
  watch :{
    '$store.state.ok': function (value) {
      if (value){
        this.ok = true;
        this.$router.push({ path: "/area/area-list" });
      }
    },
    '$store.state.errorMessage': function (message) {
      this.error = message;
      this.alert = true;
    }
  },
  computed: {
    isFormValid() {
      return Object.values(this.item).every(field => field !== '') && !this.showError;
    }
  },
  methods: {
    checkMinLength(field, minLen) {
      if (this.item[field].length <= (minLen ? minLen: this.minLen)) {
        this.error = `El campo debe tener más de ${minLen ? minLen: this.minLen} caracteres`;
        this.showError = true;
      }
    },
    checkLength(field, maxLen) {
      if (this.item[field].length >= (maxLen ? maxLen: this.maxLen)) {
        this.showError = true;
        this.error = `El campo no puede tener más de ${maxLen ? maxLen: this.maxLen} caracteres`;
        console.log("error: ", field);
        console.log("error: ", this.error);
        console.log("error: ", this.showError);
      } else {
        this.showError = false;
        this.error = '';
      }
    },
    getRouteAreaList(){
      this.$router.push({ path: "/area/area-list" });
    },
    textAlert() {
      if (this.item.id < 0) {
        return this.ok
            ? "Rol creado satisfactoriamiente, cree otro si lo desea"
            : "Error al intentar crear un Rol, revise los datos introducidos por favor";
      } else {
        return this.ok
            ? "Rol editado satisfactoriamiente"
            : "Error al intentar crear el Rol, revise los datos introducidos por favor";
      }
    },
    colorAlert() {
      return this.ok ? "success" : "danger";
    },
    createArea() {
      this.$store.dispatch('actionsCreateArea', {
        axios: this.$axios,
        name: this.item.name,
      });
    },
  },
};
</script>
<style>
.form-label {
  position: relative;
  display: inline-block;
}


.form-label span {
  position: absolute;
  left: 5px;
  top: 0;
  transition: 0.2s;
  pointer-events: none;
}

.form-label input:focus + span,
.form-label input:not(:placeholder-shown) + span {
  transform: translateY(-25px);
  font-size: 0.8em;
}
</style>
