import Utils from "@/utils/Utils";
import store from "@/store";

export default class User {
    static async retrieve(axios, id) {
        const response = await axios
            .post(
                "/graphql/",
                `query{
        userRetrieve(id: ${id}) {
          id
          avatar
          username
          firstName
          lastName
          email
          area {
            id
            name
          }
          phone
          role{
            id
            name
            rolesPermission{
              id
              name
            }
          }
        }
      }`,
                {
                    headers: {
                        "Content-Type": "application/graphql",
                    },
                }
            )
            .catch(function (error) {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    // console.log(error.response.data);
                    // console.log(error.response.status);
                    // console.log(error.response.headers);

                    // if (error.response.status === 400) {
                    // store.dispatch('actionsSetErrorMessage', 'Usuario o contraseña incorrectos');
                    // }
                    console.log("primer strike");
                    return null;
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    // console.log(error.request);
                    // store.dispatch('actionsSetErrorMessage', 'Usuario o contraseña incorrectos');
                    console.log("segudo strike");

                    return null;
                } else {
                    // Something happened in setting up the request that triggered an Error
                    // console.log('Error', error.message);
                    // store.dispatch('actionsSetErrorMessage', 'Usuario o contraseña incorrectos');
                    console.log("3er strike");

                    return null;
                }
            });

        if (response) {
            if (
                response.data.data.userRetrieve
            ) {
                response.data.data.userRetrieve.compressed = {
                    role: response.data.data.userRetrieve.role,
                    area: response.data.data.userRetrieve.area,
                    permission: response.data.data.userRetrieve.role?.rolesPermission.map((rolesPermission) => rolesPermission),
                };
            }

            response.data.data.userRetrieve.role = response.data.data.userRetrieve?.role?.id
            response.data.data.userRetrieve.area = response.data.data.userRetrieve?.area?.id

            return response.data.data.userRetrieve;
        }
    }

    static async all(axios) {
        const response = await axios?.post(
            "/graphql/",
            `query{
                userList{
                  area {
                    id
                    name
                  }
                  id
                  avatar
                  username
                  email
                  phone
                  role{
                    id
                    name
                  }
                }
              }`,
            {
                headers: {
                    "Content-Type": "application/graphql",
                },
            }
        );
        console.log("usuarios: ", response.data.data?.userList)
        return response.data.data?.userList;
    }

    static async delete(axios, id) {
        const response = await axios.post(
            "/graphql/",
            {
                query: `mutation deleteUser ($id: ID!){
                      deleteUser(id: $id){
                        ok
                      }
                    }`,
                variables: {
                    id: id,
                },
            },
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );

        return response.data.data.deleteUser.ok;
    }

    static async create(
        axios,
        password,
        username,
        email,
        area,
        phone,
        firstName,
        lastName,
        role,
    ) {
        let idEmpoyment = Utils.generateString()
        const response = await axios?.post(
            "/graphql/",
            `mutation{
                    createUser (
                    newCustomuser: {
                      password: "${password}",
                      username: "${username}",
                      email: "${email}",
                      area: ${area},
                      phone: "${phone}",
                      idEmpoyment: "${idEmpoyment}",
                      firstName: "${firstName}",
                      lastName: "${lastName}",
                      role: ${role},
                      }){
                        ok
                        errors{
                          field
                          messages
                         }
                        customuser{
                          id
                        }
                       }
                  }`,
            {
                headers: {
                    "Content-Type": "application/graphql",
                },
            }
        ).catch(function (error) {
            if (error.response) {
                store.dispatch('actionsSetErrorMessage', error.response);
                console.log("primer strike");
                return null;
            } else if (error.request) {
                console.log("segudo strike");
                store.dispatch('actionsSetErrorMessage', error.request);

                return null;
            } else {
                console.log("3er strike");
                store.dispatch('actionsSetErrorMessage', '3er strike');

                return null;
            }
        });
        if (response !== null && response.data !== undefined) {
            if (response.data.data.createUser.ok) {
                await store.dispatch('actionsSetNewIdUser', response.data.data.createUser.customuser.id);
                return response.data.data.createUser.ok;
            } else if (response.data.data.createUser.errors) {
                let errores = response.data.data.createUser.errors;
                let mensajes = [];

                for (let i = 0; i < errores.length; i++) {
                    let error = errores[i];
                    for (let j = 0; j < error.messages.length; j++) {
                        mensajes.push(error.messages[j]);
                    }
                }
                console.log("lo que tenemos: ", response.data.data.createUser.errors);
                await store.dispatch('actionsSetErrorMessage', mensajes);
                return null;
            } else if (response.data.errors) {
                console.log("lo que tenemos: ", response.data.errors);
                await store.dispatch('actionsSetErrorMessage', response.data.errors);
                return null;
            }
        } else {
            return null;
        }
    }

    static async edit(
        axios,
        id,
        username,
        email,
        area,
        phone,
        firstName,
        lastName,
        role,
        password,
    ) {
        // let idEmpoyment = Utils.generateString()
        // idEmpoyment: "${idEmpoyment}",
        if (password){
            const response = await axios?.post(
                "/graphql/",
                `mutation{
                    updateUser (
                    newCustomuser: {
                      id: ${id}
                      username: "${username}",
                      email: "${email}",
                      area: ${area},
                      phone: "${phone}",
                      firstName: "${firstName}",
                      lastName: "${lastName}",
                      role: ${role},
                      password: "${password}",
                      }){
                        ok
                        errors{
                          field
                          messages
                         }
                        customuser{
                          id
                        }
                       }
                  }`,
                {
                    headers: {
                        "Content-Type": "application/graphql",
                    },
                }
            ).catch(function (error) {
                if (error.response) {
                    store.dispatch('actionsSetErrorMessage', error.response);
                    console.log("primer strike");
                    return null;
                } else if (error.request) {
                    console.log("segudo strike");
                    store.dispatch('actionsSetErrorMessage', error.request);

                    return null;
                } else {
                    console.log("3er strike");
                    store.dispatch('actionsSetErrorMessage', '3er strike');

                    return null;
                }
            });
            if (response !== null && response.data !== undefined && !response.data.errors) {
                if (response.data.data.updateUser.ok) {
                    return response.data.data.updateUser.ok;
                }
                else if (response.data.data.updateUser.errors) {
                    let errores = response.data.data.updateUser.errors;
                    let mensajes = [];

                    for (let i = 0; i < errores.length; i++) {
                        let error = errores[i];
                        for (let j = 0; j < error.messages.length; j++) {
                            mensajes.push(error.messages[j]);
                        }
                    }
                    console.log("lo que tenemos: ", response.data.data.updateUser.errors);
                    await store.dispatch('actionsSetErrorMessage', mensajes);
                    return null;
                }
            } else if (response.data.errors){
                console.log("lo que tenemos errors: ", response.data.errors[0].message);

                await store.dispatch('actionsSetErrorMessage', response.data.errors[0].message);
                return null;
            }
            else {
                return null;
            }
        }
        else {
            const response = await axios?.post(
                "/graphql/",
                `mutation{
                    updateUser (
                    newCustomuser: {
                      id: ${id}
                      username: "${username}",
                      email: "${email}",
                      area: ${area},
                      phone: "${phone}",
                      firstName: "${firstName}",
                      lastName: "${lastName}",
                      role: ${role},
                      }){
                        ok
                        errors{
                          field
                          messages
                         }
                        customuser{
                          id
                        }
                       }
                  }`,
                {
                    headers: {
                        "Content-Type": "application/graphql",
                    },
                }
            ).catch(function (error) {
                if (error.response) {
                    store.dispatch('actionsSetErrorMessage', error.response);
                    console.log("primer strike");
                    return null;
                } else if (error.request) {
                    console.log("segudo strike");
                    store.dispatch('actionsSetErrorMessage', error.request);

                    return null;
                } else {
                    console.log("3er strike");
                    store.dispatch('actionsSetErrorMessage', '3er strike');

                    return null;
                }
            });
            if (response !== null && response.data !== undefined && !response.data.errors) {
                if (response.data.data.updateUser.ok) {
                    return response.data.data.updateUser.ok;
                }
                else if (response.data.data.updateUser.errors) {
                    let errores = response.data.data.updateUser.errors;
                    let mensajes = [];

                    for (let i = 0; i < errores.length; i++) {
                        let error = errores[i];
                        for (let j = 0; j < error.messages.length; j++) {
                            mensajes.push(error.messages[j]);
                        }
                    }
                    console.log("lo que tenemos: ", response.data.data.updateUser.errors);
                    await store.dispatch('actionsSetErrorMessage', mensajes);
                    return null;
                }
            } else if (response.data.errors){
                console.log("lo que tenemos errors: ", response.data.errors[0].message);

                await store.dispatch('actionsSetErrorMessage', response.data.errors[0].message);
                return null;
            }
            else {
                return null;
            }
        }
    }
}
